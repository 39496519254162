import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader } from "../../../components";

export default function CreateAgentPage() {
    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        position: "",
        phoneNumber: "",
        telephoneNumber: "",
        extensionCode: "",
        shortCode: "",
        organization: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [organizations, setOrganizations] = useState([]);

    const navigate = useNavigate();
    const { jwtToken } = useSelector((state) => state.user);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            await axios.post("/admin/agents/create", data, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });

            navigate(-1);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const fetchOrganization = async () => {
        try {
            const response = await axios.get(`/admin/organizations/all`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setOrganizations(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchOrganization();
    }, []);

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Create Agent</h2>
            <form onSubmit={handleSubmit}>
                <div className="place mb-5">
                    <label htmlFor="input-place">Organization</label>
                    <select
                        name="organization"
                        onChange={handleChange}
                        value={data.organization || ""}
                        id=""
                        required
                        className="w-full"
                    >
                        <option value="" hidden>
                            Select Organization
                        </option>
                        {organizations?.map((org, index) => {
                            return (
                                <option value={org?._id} key={index}>
                                    {org?.organizationName}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Agent Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter Agent Name"
                        name="name"
                        value={data.name || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Email</label>
                    <input
                        type="email"
                        className=""
                        placeholder="Enter Email"
                        name="email"
                        value={data.email || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Position</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter Position"
                        name="position"
                        value={data.position || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Short Code</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Ex: TCT"
                        name="shortCode"
                        value={data.shortCode || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Phone Number</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter phone number"
                        name="phoneNumber"
                        value={data.phoneNumber || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Telephone Number</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter telephone number"
                        name="telephoneNumber"
                        value={data.telephoneNumber || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Extension Code</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter extension code"
                        name="extensionCode"
                        value={data.extensionCode || ""}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="place mb-5">
                    <label htmlFor="input-place">Password</label>
                    <input
                        type="password"
                        className=""
                        placeholder="Enter Password"
                        name="password"
                        value={data.password || ""}
                        onChange={handleChange}
                        required
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Add Agent"}
                </button>
            </form>
        </div>
    );
}
