import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function AgentPrivateRoute({ children, ...rest }) {
    const { user, isLoggedIn } = useSelector((state) => state.user);

    if (!isLoggedIn || user?.role !== "agent") {
        return <Navigate replace to="/login" />;
    }

    return children;
}

export default AgentPrivateRoute;
