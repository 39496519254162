import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../../axios";
import {
    handleTransferChange,
    updateIsTransferQuotationDisabled,
} from "../../../redux/slices/quotationsSlice";

export default function TransferQuotationForm() {
    const [serachError, setSearchError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [prices, setPrices] = useState({
        sevenSeater: 0,
        fourteenSeater: 0,
        perPersonPrice: 0,
    });

    const {
        places,
        transfer,
        isTransferQuotationDisabled,
        noOfAdults,
        noOfChildren,
    } = useSelector((state) => state.quotations);
    const { jwtToken } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        dispatch(
            handleTransferChange({ name: e.target.name, value: e.target.value })
        );
    };

    const handleSearch = useCallback(async () => {
        try {
            setSearchError("");

            setPrices((prev) => {
                return {
                    ...prev,
                    sevenSeater: 0,
                    fourteenSeater: 0,
                    perPersonPrice: 0,
                };
            });

            if (transfer?.transferFrom && transfer?.transferTo) {
                setIsLoading(true);

                const response = await axios.post(
                    "/transfers/price-info",
                    {
                        transferFrom: transfer?.transferFrom,
                        transferTo: transfer?.transferTo,
                        noOfSeats: transfer?.noOfSeats,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                setPrices((prev) => {
                    return {
                        ...prev,
                        sevenSeater: response?.data?.price1,
                        fourteenSeater: response?.data?.price2,
                        perPersonPrice: 0,
                    };
                });
                setIsLoading(false);
            }
        } catch (err) {
            setSearchError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    }, [transfer?.transferFrom, transfer?.transferTo, dispatch, jwtToken]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    useEffect(() => {
        let totalPax =
            (!isNaN(noOfAdults) ? Number(noOfAdults) : 0) +
            (!isNaN(noOfChildren) ? Number(noOfChildren) : 0);

        if (prices?.sevenSeater && prices?.fourteenSeater) {
            let totalCapacity =
                (transfer?.is7SeaterTransfer === true
                    ? 4 * Number(transfer?.sevenSeaterCount)
                    : 0) +
                (transfer?.is14SeaterTransfer === true
                    ? 10 * Number(transfer?.fourteenSeaterCount)
                    : 0);
            let totalAirportTransferPrice =
                (transfer?.is7SeaterTransfer === true
                    ? prices?.sevenSeater * Number(transfer?.sevenSeaterCount)
                    : 0) +
                (transfer?.is14SeaterTransfer === true
                    ? prices?.fourteenSeater * Number(transfer?.fourteenSeaterCount)
                    : 0);

            let divVal = 1;
            if (totalPax > totalCapacity) {
                divVal = totalCapacity;
            } else if (totalPax > 1 && totalPax <= totalCapacity) {
                divVal = totalPax;
            }

            if (transfer?.isReturn === true) {
                setPrices((prev) => {
                    return {
                        ...prev,
                        perPersonPrice: totalAirportTransferPrice / divVal,
                    };
                });
            } else {
                setPrices((prev) => {
                    return {
                        ...prev,
                        perPersonPrice: totalAirportTransferPrice / divVal / 2,
                    };
                });
            }
        }
    }, [
        prices.sevenSeater,
        prices.fourteenSeater,
        noOfAdults,
        noOfChildren,
        transfer?.isReturn,
        transfer?.is7SeaterTransfer,
        transfer?.sevenSeaterCount,
        transfer?.is14SeaterTransfer,
        transfer?.fourteenSeaterCount,
    ]);

    return (
        <div>
            <h1 className="text-base font-[600] mb-7 text-blue-500">
                Transfer Quotation
            </h1>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Location From
                </label>
                <select
                    id=""
                    className="w-[100%]"
                    name="transferFrom"
                    value={transfer?.transferFrom}
                    onChange={handleChange}
                >
                    <option value="" hidden>
                        Select Location
                    </option>
                    {places?.map((place, index) => {
                        if (place?.isAirport === false) {
                            return (
                                <React.Fragment key={index}></React.Fragment>
                            );
                        }
                        return (
                            <option key={place?._id} value={place?._id}>
                                {place?.name} {place.isAirport && "✈️"}
                            </option>
                        );
                    })}
                    {places?.map((place, index) => {
                        if (place?.isAirport === true) {
                            return (
                                <React.Fragment key={index}></React.Fragment>
                            );
                        }
                        return (
                            <option key={place?._id} value={place?._id}>
                                {place?.name} {place.isAirport && "✈️"}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Transfer To
                </label>
                <select
                    id=""
                    className="w-[100%]"
                    name="transferTo"
                    value={transfer?.transferTo}
                    onChange={handleChange}
                >
                    <option value="" hidden>
                        Select Location
                    </option>
                    {places?.map((place, index) => {
                        if (place?.isAirport === false) {
                            return (
                                <React.Fragment key={index}></React.Fragment>
                            );
                        }
                        return (
                            <option key={place?._id} value={place?._id}>
                                {place?.name} {place.isAirport && "✈️"}
                            </option>
                        );
                    })}
                    {places?.map((place, index) => {
                        if (place?.isAirport === true) {
                            return (
                                <React.Fragment key={index}></React.Fragment>
                            );
                        }
                        return (
                            <option key={place?._id} value={place?._id}>
                                {place?.name} {place.isAirport && "✈️"}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Transfer Type
                </label>
                <div className="grid grid-cols-2 gap-[1.5em] w-full">
                    <div>
                        <div className="flex items-center gap-[10px]">
                            <input
                                type="checkbox"
                                className="w-[16px] h-[16px]"
                                name="is7SeaterTransfer"
                                defaultChecked={
                                    transfer?.is7SeaterTransfer || false
                                }
                                onChange={(e) => {
                                    dispatch(
                                        handleTransferChange({
                                            name: e.target.name,
                                            value: e.target.checked,
                                        })
                                    );
                                }}
                            />
                            <label htmlFor="" className="mb-0">
                                7 seater
                            </label>
                        </div>
                        {transfer?.is7SeaterTransfer === true && (
                            <input
                                type="number"
                                className="w-full mt-3"
                                name="sevenSeaterCount"
                                value={transfer?.sevenSeaterCount || ""}
                                onChange={(e) => {
                                    dispatch(
                                        handleTransferChange({
                                            name: e.target.name,
                                            value: Number(e.target.value),
                                        })
                                    );
                                }}
                            />
                        )}
                    </div>

                    <div>
                        <div className="flex items-center gap-[10px]">
                            <input
                                type="checkbox"
                                className="w-[16px] h-[16px]"
                                name="is14SeaterTransfer"
                                defaultChecked={
                                    transfer?.is14SeaterTransfer || false
                                }
                                onChange={(e) => {
                                    dispatch(
                                        handleTransferChange({
                                            name: e.target.name,
                                            value: e.target.checked,
                                        })
                                    );
                                }}
                            />
                            <label htmlFor="" className="mb-0">
                                14 seater
                            </label>
                        </div>
                        {transfer?.is14SeaterTransfer === true && (
                            <input
                                type="number"
                                className="w-full mt-3"
                                name="fourteenSeaterCount"
                                value={transfer?.fourteenSeaterCount || ""}
                                onChange={(e) => {
                                    dispatch(
                                        handleTransferChange({
                                            name: e.target.name,
                                            value: Number(e.target.value),
                                        })
                                    );
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex gap-[2em] mt-2">
                <span className="w-[100%] max-w-[180px]"></span>
                <div>
                    <span className="text-sm text-gray-500 leading-[28px] block">
                        Before submitting quotation make sure. Above transfer
                        combination exists.
                    </span>
                </div>
            </div>
            <div className="flex gap-[2em] mt-2">
                <span className="w-[100%] max-w-[180px]"></span>
                <div className="flex items-center gap-[25px]">
                    <div className="flex items-center gap-[10px] mt-2">
                        <input
                            type="radio"
                            className="w-[16px] h-[16px]"
                            name="return-transfer"
                            defaultChecked={transfer?.isReturn === true}
                            onChange={(e) =>
                                dispatch(
                                    handleTransferChange({
                                        name: "isReturn",
                                        value: true,
                                    })
                                )
                            }
                        />
                        <label htmlFor="" className="mb-0">
                            Return Transfer
                        </label>
                    </div>
                    <div className="flex items-center gap-[10px] mt-2">
                        <input
                            type="radio"
                            className="w-[16px] h-[16px]"
                            name="return-transfer"
                            defaultChecked={transfer?.isReturn === false}
                            onChange={(e) =>
                                dispatch(
                                    handleTransferChange({
                                        name: "isReturn",
                                        value: false,
                                    })
                                )
                            }
                        />
                        <label htmlFor="" className="mb-0">
                            One Way Transfer
                        </label>
                    </div>
                </div>
            </div>
            <div className="flex gap-[2em] mt-6">
                <span className="w-[100%] max-w-[180px]">Total</span>
                <span className="text-sm font-medium">
                    {isLoading
                        ? "Loading..."
                        : prices.perPersonPrice?.toFixed(2) || 0}{" "}
                    AED
                </span>
            </div>
            <div className="flex gap-[2em] mt-6">
                {/* <span className="w-[100%] max-w-[180px]"></span> */}
                <div>
                    {serachError && (
                        <span className="text-sm text-red-500 block font-medium block mb-3">
                            {serachError}
                        </span>
                    )}
                    <div className="flex items-center gap-[10px] mt-2">
                        <input
                            type="checkbox"
                            className="w-[16px] h-[16px]"
                            checked={isTransferQuotationDisabled}
                            onChange={(e) =>
                                dispatch(
                                    updateIsTransferQuotationDisabled(
                                        e.target.checked
                                    )
                                )
                            }
                            id="transfer-not-required"
                        />
                        <label
                            htmlFor="transfer-not-required"
                            className="mb-0 font-medium"
                        >
                            Transfer Not Required
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}
