import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import { Loader } from "../../components";
import { useSelector } from "react-redux";
import getFormatedDate from "../../utils/getFormatedDate";

export default function SingleAmendmentPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [amendment, setAmendment] = useState({});

    const { jwtToken } = useSelector((state) => state.user);
    const { id } = useParams();

    const fetchAmendment = async () => {
        try {
            setIsLoading(true);

            const response = await axios.get(
                `/admin/quotations/amendment/${id}`,
                { headers: { Authorization: `Bearer ${jwtToken}` } }
            );

            setAmendment(response.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchAmendment();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="text-[15px]">
            <table>
                <tbody>
                    <tr>
                        <td className="py-1 pr-2">Quotation Number</td>
                        <td className="px-2">:</td>
                        <td className="px-2">{amendment?.quotationNumber}</td>
                    </tr>
                    <tr>
                        <td className="py-1 pr-2">No of adults</td>
                        <td className="px-2">:</td>
                        <td className="px-2">{amendment?.noOfAdults}</td>
                    </tr>
                    <tr>
                        <td className="py-1 pr-2">No of children</td>
                        <td className="px-2">:</td>
                        <td className="px-2">{amendment?.noOfChildren}</td>
                    </tr>
                </tbody>
            </table>

            {amendment?.transferQuotation && (
                <div className="mt-6">
                    <h1 className="font-medium text-blue-500 text-[17px] mb-1">
                        Transfer Quotation
                    </h1>
                    <table>
                        <tbody>
                            <tr>
                                <td className="py-1 pr-2">Airport</td>
                                <td className="px-2">:</td>
                                <td className="px-2">
                                    {
                                        amendment?.transferQuotation
                                            ?.transferFromName
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="py-1 pr-2">Place</td>
                                <td className="px-2">:</td>
                                <td className="px-2">
                                    {
                                        amendment?.transferQuotation
                                            ?.transferToName
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="py-1 pr-2">No Of Seats</td>
                                <td className="px-2">:</td>
                                <td className="px-2">
                                    {amendment?.transferQuotation?.noOfSeats +
                                        " Seater"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {amendment?.hotelQuotation && (
                <div className="mt-6">
                    <h1 className="font-medium text-blue-500 text-[17px] mb-1">
                        Hotel Quotaion
                    </h1>
                    <table>
                        <tbody>
                            <tr>
                                <td className="py-1 pr-2">Check In Date</td>
                                <td className="px-2">:</td>
                                <td className="px-2">
                                    {getFormatedDate(
                                        amendment?.hotelQuotation?.checkInDate
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="py-1 pr-2">Check Out Date</td>
                                <td className="px-2">:</td>
                                <td className="px-2">
                                    {getFormatedDate(
                                        amendment?.hotelQuotation?.checkOutDate
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-3">
                        <h5 className="text-gray-500 font-medium mb-1">
                            Hotels
                        </h5>
                        <table>
                            <thead className="">
                                <tr className="text-left text-[15px]">
                                    <th className="px-[12px] font-[400] border py-2">
                                        Hotel Name
                                    </th>
                                    <th className="px-[10px] font-[400] border">
                                        Hotel Place
                                    </th>
                                    <th className="px-[10px] font-[400] border">
                                        Star Category
                                    </th>
                                    <th className="px-[10px] font-[400] border">
                                        Price
                                    </th>
                                    <th className="px-[10px] font-[400] border">
                                        Rooms
                                    </th>
                                    {/* <th>Total</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {amendment?.hotelQuotation?.hotels?.map(
                                    (hotel, index) => {
                                        return (
                                            <tr key={index} className="">
                                                <td className="px-[12px] border py-2">
                                                    {hotel?.hotelName}
                                                </td>
                                                <td className="px-[12px] border py-2">
                                                    {hotel?.placeName}
                                                </td>
                                                <td className="px-[12px] border py-2">
                                                    {hotel?.starCategory}
                                                </td>
                                                <td className="px-[12px] border py-2">
                                                    {hotel?.ppTotalPrice} AED
                                                </td>
                                                <td className="px-[12px] border py-2">
                                                    <RoomTable hotel={hotel} />
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {amendment?.excursionQuotation && (
                <div className="mt-6">
                    <h1 className="font-medium text-blue-500 text-[17px] mb-2">
                        Excursion Quotation
                    </h1>

                    <table>
                        <tbody>
                            <tr>
                                <td className="py-1 pr-2">Adult Total</td>
                                <td className="px-2">:</td>
                                <td className="px-2">
                                    {amendment?.excursionQuotation?.adultTotal +
                                        " AED" || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <td className="py-1 pr-2">Children Total</td>
                                <td className="px-2">:</td>
                                <td className="px-2">
                                    {amendment?.excursionQuotation
                                        ?.childrenTotal + " AED" || "N/A"}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h5 className="text-gray-500 font-medium mb-1 mt-3">
                        Inclusions
                    </h5>
                    <ul className="list-disc pl-[16px]">
                        {amendment?.excursionQuotation?.excursions?.map(
                            (exc, index) => {
                                return (
                                    <li key={index}>
                                        {exc?.excursionName}{" "}
                                        <span className="text-blue-500">
                                            ({exc?.excursionType})
                                        </span>
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </div>
            )}

            {amendment?.visa && (
                <div className="mt-6">
                    <h1 className="font-medium text-blue-500 text-[17px] mb-2">
                        VISA Quotation
                    </h1>

                    <ul className="list-disc pl-[16px]">
                        <li>{amendment?.visa?.name}</li>
                    </ul>
                </div>
            )}

            <div className="mt-6">
                <table>
                    <tbody>
                        <tr>
                            <td className="py-1 pr-2">Tourism Fee included</td>
                            <td className="px-2">:</td>
                            <td className="px-2">
                                {amendment?.isTourismFeeIncluded
                                    ? "True"
                                    : "False"}
                            </td>
                        </tr>
                        <tr>
                            <td className="py-1 pr-2">Markup</td>
                            <td className="px-2">:</td>
                            <td className="px-2">
                                {amendment?.markup}
                                {amendment?.markupType === "flat"
                                    ? " AED"
                                    : "%"}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

const RoomTable = ({ hotel }) => {
    return (
        <table>
            <thead>
                <tr className="text-sm">
                    <th className="font-medium px-[10px] border py-2 ">
                        Room Type
                    </th>
                    <th className="font-medium px-[10px] border">No persons</th>
                    <th className="font-medium px-[10px] border ">Price</th>
                </tr>
            </thead>
            <tbody>
                {hotel?.roomOccupancies?.map((room, index) => {
                    return (
                        <tr key={index} className="text-sm">
                            <td className="px-[10px] border py-2">
                                {room?.roomOccupancyName}
                            </td>
                            <td className="px-[10px] border py-2">
                                {room?.noOfPersons}
                            </td>
                            <td className="px-[10px] border py-2">
                                {room?.price} AED
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
