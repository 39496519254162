import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { saveUser } from "../../redux/slices/userSlices";
import axios from "../../axios";
import { logoPng } from "../../assets/images";
import { BtnLoader } from "../../components";

function LoginScreen() {
    const [data, setData] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            const response = await axios.post("/users/login", data);

            dispatch(saveUser(response.data));

            window.location.reload();
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="flex items-center gap-[10px] mb-10">
                <img src={logoPng} alt="" className="w-[40px]" />
                <h1 className="font-[700] text-2xl">
                    <span className="text-[#002366]">Quotation</span>{" "}
                    <span className="text-[#f00]">Tool</span>
                </h1>
            </div>
            <div className="w-full max-w-[420px] border p-7 rounded">
                <div className="mb-5">
                    <h1 className="font-[600] text-xl text-center">Login</h1>
                    <span className="block text-center text-sm text-gray-500 mt-1">
                        Welcome back to Quotation Tool
                    </span>
                </div>
                <form action="" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="">Email</label>
                        <input
                            type="email"
                            placeholder="Ex: example@gmail.com"
                            name="email"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Password</label>
                        <input
                            type="password"
                            placeholder="********"
                            name="password"
                            onChange={handleChange}
                        />
                    </div>
                    {error && (
                        <span className="block text-sm text-red-500 mt-2">
                            {error}
                        </span>
                    )}
                    <button className="w-full mt-4">
                        {isLoading ? <BtnLoader /> : "Login"}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default LoginScreen;
