import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader, Loader } from "../../../components";

export default function VisaEditPage() {
    const [data, setData] = useState({
        name: "",
        price: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { id } = useParams();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            await axios.patch(
                `/admin/visa/update/${id}`,
                {
                    ...data,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            navigate("/admin/visa");
        } catch (error) {
            setError(
                error?.response?.data?.error ||
                    "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const fetchVisa = useCallback(async () => {
        try {
            setIsFetching(true);

            const { data } = await axios.get(`/admin/visa/single/${id}`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setData((prev) => {
                return { ...prev, name: data?.name, price: data?.price };
            });
            setIsFetching(false);
        } catch (err) {
            console.log(err);
        }
    }, [jwtToken, id]);

    useEffect(() => {
        fetchVisa();
    }, [fetchVisa]);

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Add Visa</h2>
            <form onSubmit={submitHandler}>
                <div className="mb-4">
                    <label htmlFor="countryField">Visa Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter Visa Name"
                        id="input-place"
                        name="name"
                        value={data.name || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Visa Price</label>
                    <input
                        type="number"
                        className=""
                        placeholder="Enter Price"
                        id="input-place"
                        name="price"
                        value={data.price || ""}
                        onChange={handleChange}
                        required
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Edit Visa"}
                </button>
            </form>
        </div>
    );
}
