import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader } from "../../../components";

export default function CreateVisaPage() {
    const [data, setData] = useState({
        name: "",
        price: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            setError("");

            await axios.post(
                "/admin/visa/add",
                {
                    ...data,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            navigate("/admin/visa");
        } catch (error) {
            setError(
                error?.response?.data?.error ||
                    "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Add Visa</h2>
            <form onSubmit={submitHandler}>
                <div className="mb-4">
                    <label htmlFor="countryField">Visa Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter Visa Name"
                        id="input-place"
                        name="name"
                        value={data.name || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Visa Price</label>
                    <input
                        type="number"
                        className=""
                        placeholder="Enter Price"
                        id="input-place"
                        name="price"
                        value={data.price || ""}
                        onChange={handleChange}
                        required
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Add Visa"}
                </button>
            </form>
        </div>
    );
}
