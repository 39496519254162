import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";

import axios from "../../../axios";
import { Loader } from "../../../components";

function ExcursionsList() {
    const [excursions, setExcursions] = useState([]);
    const [filteredExcursions, setFilteredExcursions] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);

    const fetchExcursions = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get("/admin/excursions/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setExcursions(response.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, []);

    const handleDelete = async (id) => {
        try {
            const isConfirm = window.confirm("Are you sure to delete?");
            if (isConfirm) {
                await axios.delete(`/admin/excursions/delete/${id}`, {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                });
                const filteredItems = excursions.filter((item) => {
                    return item?._id !== id;
                });
                setExcursions(filteredItems);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchExcursions();
    }, [fetchExcursions]);

    useEffect(() => {
        if (excursions?.length > 0 && searchText) {
            const searchedExcursions = excursions.filter((excursion) => {
                return excursion?.excursionName
                    ?.toLowerCase()
                    ?.includes(searchText);
            });
            setFilteredExcursions(searchedExcursions);
        } else {
            setFilteredExcursions(excursions);
        }
    }, [searchText, excursions]);

    return (
        <div className="">
            <div className="flex items-center justify-between mb-5">
                <h1 className="text-lg font-[600]">Excursions</h1>
                <div className="flex items-center gap-[20px]">
                    <div>
                        <input
                            type="text"
                            placeholder="Search here..."
                            value={searchText || ""}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                    <Link to="/admin/excursions/create">
                        <button className="px-4 bg-darkColor py-2 rounded-md">
                            + Create
                        </button>
                    </Link>
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <table className="w-full text-sm text-left text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="py-3 px-3">
                                Excursion Name
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Description
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Type
                            </th>
                            <th className="py-3 px-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredExcursions?.map((excursion, index) => (
                            <tr className="bg-white border-b " key={index}>
                                <td className="py-3 px-3">
                                    {excursion?.excursionName}{" "}
                                </td>
                                <td className="py-3 px-3">
                                    {excursion?.description || "N/A"}{" "}
                                </td>
                                <td className="py-3 px-3">
                                    {excursion?.excursionType}{" "}
                                </td>
                                <td className="py-3 px-3">
                                    <div className="flex items-center gap-[15px]">
                                        <Link
                                            to={`/admin/excursions/edit/${excursion?._id}`}
                                        >
                                            <button className="flex items-center justify-center h-auto p-0 text-green-500 text-xl bg-transparent">
                                                <BiEditAlt />
                                            </button>
                                        </Link>
                                        <button
                                            onClick={() =>
                                                handleDelete(excursion?._id)
                                            }
                                            className="flex items-center justify-center p-0 h-auto text-red-500 text-xl bg-transparent"
                                        >
                                            <MdDelete />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default ExcursionsList;
