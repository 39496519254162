import { BsShop, BsGrid, BsListUl, BsPeople, BsCompass } from "react-icons/bs";

const adminSidebarLinks = [
    {
        name: "Dashboard",
        to: "/admin/",
        icon: <BsGrid />,
    },
    {
        name: "Quotations",
        to: "/admin/quotations",
        icon: <BsGrid />,
    },
    {
        name: "Places",
        to: "/admin/places",
        icon: <BsShop />,
    },
    {
        name: "Airports",
        to: "/admin/airports",
        icon: <BsListUl />,
    },
    {
        name: "Transfers",
        to: "/admin/transfers",
        icon: <BsListUl />,
    },
    {
        name: "Hotels",
        to: "/admin/hotels",
        icon: <BsListUl />,
    },
    {
        name: "Excursions",
        to: "/admin/excursions",
        icon: <BsPeople />,
    },
    {
        name: "Room Occupancy",
        to: "/admin/room-occupancies",
        icon: <BsCompass />,
    },
    {
        name: "Room Types",
        to: "/admin/room-types",
        icon: <BsCompass />,
    },
    {
        name: "Visa",
        to: "/admin/visa",
        icon: <BsCompass />,
    },
    {
        name: "Organizations",
        to: "/admin/organizations",
        icon: <BsCompass />,
    },
    // {
    //     name: "Agents",
    //     to: "/admin/agents",
    //     icon: <BsCompass />,
    // },
];

export default adminSidebarLinks;
