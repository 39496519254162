import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import {
    changeSelectedHotel,
    handleHotelsDataChange,
    handleRowItemChange,
    removeHotel,
    updateHotelBreakfastIncludedOrNot,
    updateHotelIsRefundable,
} from "../../../redux/slices/quotationsSlice";
import { useHandleClickOutside } from "../../../hooks";

const tourFee = {
    "2 star": 10,
    "3 star": 10,
    "4 star": 15,
    "5 star": 20,
    "7 star": 20,
    apartment: 0,
};

export default function SingleHotelForm({ hotel, hotelIndex }) {
    const [searchText, setSearchText] = useState("");
    const [searchedHotels, setSearchedHotels] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isRoomTypeDropdownOpen, setIsRoomTypeDropdownOpen] = useState(false);
    const [searchedRoomTypes, setSearchedRoomTypes] = useState([]);

    const dispatch = useDispatch();
    const { hotels, roomTypes, hotelQt } = useSelector(
        (state) => state.quotations
    );
    const dropdownWrapperRef = useRef();
    const roomTypeDropdownWrapperRef = useRef();
    useHandleClickOutside(dropdownWrapperRef, () => setIsDropdownOpen(false));
    useHandleClickOutside(roomTypeDropdownWrapperRef, () =>
        setIsRoomTypeDropdownOpen(false)
    );

    const onRowDataChange = (e, hotelIndex, roomIndex) => {
        dispatch(
            handleRowItemChange({
                value: e.target.value,
                hotelIndex,
                roomIndex,
            })
        );
    };

    useEffect(() => {
        if (searchText) {
            const filteredHotels = hotels?.filter((hotel) => {
                return (
                    hotel?.name
                        ?.toLowerCase()
                        ?.includes(searchText?.toLowerCase()) ||
                    hotel?.place
                        ?.toLowerCase()
                        ?.includes(searchText?.toLowerCase())
                );
            });
            setSearchedHotels(filteredHotels);
        } else {
            setSearchedHotels(hotels);
        }
    }, [searchText, hotels]);

    useEffect(() => {
        if (hotel?.roomTypeName) {
            const filteredRoomTypes = roomTypes?.filter((roomType) => {
                return roomType?.roomTypeName
                    ?.toLowerCase()
                    ?.includes(hotel?.roomTypeName?.toLowerCase());
            });
            setSearchedRoomTypes(filteredRoomTypes);
        } else {
            setSearchedRoomTypes(roomTypes);
        }
    }, [hotel?.roomTypeName, roomTypes]);

    return (
        <div className="border border-dashed p-5 mt-7 bg-[#f9f9f9]">
            <div className="mb-7 flex items-center justify-between gap-[10px]">
                <h2 className="text-[15px] font-[500]">
                    Hotel {hotelIndex + 1}
                </h2>
                <button
                    type="button"
                    className="p-0 h-auto text-red-500 text-lg"
                    onClick={() => {
                        dispatch(removeHotel(hotelIndex));
                    }}
                >
                    <IoMdClose />
                </button>
            </div>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Hotel Name
                </label>
                <div className="w-full">
                    <div ref={dropdownWrapperRef} className="relative w-full">
                        <input
                            type="text"
                            placeholder="Search here..."
                            onChange={(e) => setSearchText(e.target.value)}
                            onFocus={() => setIsDropdownOpen(true)}
                            value={searchText || ""}
                        />

                        {isDropdownOpen && searchText && (
                            <div className="absolute top-[100%] left-0 right-0 bg-[#fff] max-h-[200px] overflow-y-auto shadow-lg z-10">
                                {searchedHotels?.length < 1 ? (
                                    <div className="flex items-center justify-center h-full gap-[10px] p-5">
                                        <span className="text-sm text-gray-500">
                                            No Hotels Found
                                        </span>
                                    </div>
                                ) : (
                                    searchedHotels?.map((hotel, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="flex items-center gap-[10px] px-4 py-[7px] hover:bg-[#f6f6f6] cursor-pointer text-sm "
                                                onClick={() => {
                                                    dispatch(
                                                        changeSelectedHotel({
                                                            hotelIndex,
                                                            hotel,
                                                        })
                                                    );
                                                    setSearchText(hotel?.name);
                                                    setIsDropdownOpen(false);
                                                }}
                                            >
                                                <span className="flex items-center justify-center bg-blue-200 text-blue-500 w-[18px] h-[18px] min-w-[18px] min-h-[18px] rounded-full ">
                                                    +
                                                </span>
                                                <span>
                                                    {hotel?.name} - (
                                                    {hotel?.place})
                                                </span>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        )}
                    </div>
                    {hotel?.hotelName && (
                        <>
                            <span className="block text-sm mt-[8px]">
                                Hotel - {hotel?.hotelName}
                            </span>
                            <span className="block text-sm mt-[8px]">
                                Place - {hotel?.placeName}
                            </span>
                            <span className="block text-sm mt-[8px]">
                                Star Category - {hotel?.starCategory || "N/A"}
                            </span>
                        </>
                    )}
                </div>
            </div>

            <div className="flex items-start gap-[2em] mt-6">
                <span className="w-[100%] max-w-[180px]"></span>
                <div className="flex items-center gap-[15px]">
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            name={"isBreakfastIncluded" + hotelIndex}
                            id=""
                            className="w-[16px] h-[16px]"
                            defaultChecked={
                                hotel?.isBreakfastIncluded === false
                            }
                            onChange={(e) =>
                                dispatch(
                                    updateHotelBreakfastIncludedOrNot({
                                        hotelIndex,
                                        isBreakfastIncluded: false,
                                    })
                                )
                            }
                        />
                        <label htmlFor="" className="mb-0">
                            Room Only
                        </label>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            name={"isBreakfastIncluded" + hotelIndex}
                            id=""
                            className="w-[16px] h-[16px]"
                            defaultChecked={hotel?.isBreakfastIncluded === true}
                            onChange={(e) =>
                                dispatch(
                                    updateHotelBreakfastIncludedOrNot({
                                        hotelIndex,
                                        isBreakfastIncluded: true,
                                    })
                                )
                            }
                        />
                        <label htmlFor="" className="mb-0">
                            Bed & Breakfast
                        </label>
                    </div>
                </div>
            </div>

            <div className="flex items-start gap-[2em] mt-6">
                <span className="w-[100%] max-w-[180px]"></span>
                <div className="flex items-center gap-[15px]">
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            name={"isRefundable" + hotelIndex}
                            id=""
                            className="w-[16px] h-[16px]"
                            defaultChecked={hotel?.isRefundable === false}
                            onChange={(e) =>
                                dispatch(
                                    updateHotelIsRefundable({
                                        hotelIndex,
                                        isRefundable: false,
                                    })
                                )
                            }
                        />
                        <label htmlFor="" className="mb-0">
                            Non Refundable
                        </label>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            name={"isRefundable" + hotelIndex}
                            id=""
                            className="w-[16px] h-[16px]"
                            defaultChecked={hotel?.isRefundable === true}
                            onChange={(e) =>
                                dispatch(
                                    updateHotelIsRefundable({
                                        hotelIndex,
                                        isRefundable: true,
                                    })
                                )
                            }
                        />
                        <label htmlFor="" className="mb-0">
                            Refundable
                        </label>
                    </div>
                </div>
            </div>

            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Room Type
                </label>
                <div
                    className="relative w-full"
                    ref={roomTypeDropdownWrapperRef}
                >
                    <input
                        type="text"
                        placeholder="Enter room type"
                        onChange={(e) => {
                            dispatch(
                                handleHotelsDataChange({
                                    hotelIndex,
                                    name: "roomTypeName",
                                    value: e.target.value,
                                })
                            );
                        }}
                        value={hotel?.roomTypeName || ""}
                        onFocus={() => setIsRoomTypeDropdownOpen(true)}
                    />

                    {isRoomTypeDropdownOpen && hotel?.roomTypeName && (
                        <div className="absolute top-[100%] left-0 right-0 bg-[#fff] max-h-[200px] overflow-y-auto shadow-lg z-10">
                            {searchedRoomTypes?.length < 1 ? (
                                <div className="flex items-center justify-center h-full gap-[10px] p-5">
                                    <span className="text-sm text-gray-500">
                                        No Room types Found
                                    </span>
                                </div>
                            ) : (
                                searchedRoomTypes?.map((roomType, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex items-center gap-[10px] px-4 py-[7px] hover:bg-[#f6f6f6] cursor-pointer text-sm "
                                            onClick={() => {
                                                dispatch(
                                                    handleHotelsDataChange({
                                                        hotelIndex,
                                                        name: "roomTypeName",
                                                        value: roomType?.roomTypeName,
                                                    })
                                                );
                                                setIsRoomTypeDropdownOpen(
                                                    false
                                                );
                                            }}
                                        >
                                            <span className="flex items-center justify-center bg-blue-200 text-blue-500 w-[18px] h-[18px] min-w-[18px] min-h-[18px] rounded-full ">
                                                +
                                            </span>
                                            <span>
                                                {roomType?.roomTypeName}
                                            </span>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="border border-dashed bg-[#f1f1f1] p-5 mt-6">
                <div className="mb-7 flex justify-between gap-[10px]">
                    <h2 className="text-[14px] font-[500]">Room Occupancy</h2>
                </div>
                <div className="grid grid-cols-5 gap-[10px]">
                    {hotel?.roomOccupancies?.map((item, roomIndex) => {
                        return (
                            <div className="" key={roomIndex}>
                                <label htmlFor="">
                                    {item?.roomOccupancyShortName}
                                </label>
                                <input
                                    type="number"
                                    name="price"
                                    value={item?.price || ""}
                                    onChange={(e) =>
                                        onRowDataChange(
                                            e,
                                            hotelIndex,
                                            roomIndex
                                        )
                                    }
                                    placeholder="Price"
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            {hotelQt?.isTourismFeeIncluded === true && (
                <span className="text-sm mt-[1em] block text-blue-500">
                    *{" "}
                    {(hotel?.starCategory === "apartment"
                        ? tourFee.apartment
                        : tourFee[hotel?.starCategory]) || 0}{" "}
                    AED will be added as tourism fee
                </span>
            )}
        </div>
    );
}
