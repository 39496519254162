import React, { useCallback, useEffect, useState } from "react";
import { BtnLoader, Loader } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../axios";
import { useSelector } from "react-redux";

export default function RoomTypeEditPage() {
    const [data, setData] = useState({
        roomTypeName: "",
        roomTypeShortName: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    const navigate = useNavigate();
    const { id } = useParams();
    const { jwtToken } = useSelector((state) => state.user);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            await axios.patch(
                `/admin/room-types/update/${id}`,
                { ...data },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            navigate("/admin/room-types");
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const fetchRoomType = useCallback(async () => {
        try {
            setIsFetching(true);

            const { data } = await axios.get(`/admin/room-types/single/${id}`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setData((prev) => {
                return {
                    ...prev,
                    roomTypeName: data?.roomTypeName,
                    roomTypeShortName: data?.roomTypeShortName,
                };
            });
            setIsFetching(false);
        } catch (err) {
            console.log(err);
        }
    }, [id, jwtToken]);

    useEffect(() => {
        fetchRoomType();
    }, [fetchRoomType]);

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Edit Room Type</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="countryField">Room Type Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter Room Type"
                        id="input-place"
                        name="roomTypeName"
                        value={data.roomTypeName || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="countryField">
                        Room Type Short Name
                    </label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter Room Type Short Name"
                        id="input-place"
                        name="roomTypeShortName"
                        value={data.roomTypeShortName || ""}
                        onChange={handleChange}
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Update Room Type"}
                </button>
            </form>
        </div>
    );
}
