import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../../axios";
import { BtnLoader } from "../../../components";

export default function AddOrganizationPage() {
    const [data, setData] = useState({
        organizationName: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);

            await axios.post(`/admin/organizations/create`, data, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setIsLoading(false);
            navigate(`/admin/organizations`);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Create Organization</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="">Organization Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Ex: Sky Travels"
                        name="organizationName"
                        value={data.organizationName}
                        onChange={handleChange}
                        required
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Create Organization"}
                </button>
            </form>
        </div>
    );
}
