import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader, Loader } from "../../../components";

export default function EditOrganizationPage() {
    const [data, setData] = useState({
        organizationName: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [isPageLoading, setIsPageLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { id } = useParams();

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);

            await axios.patch(`/admin/organizations/update/${id}`, data, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setIsLoading(false);
            navigate(`/admin/organizations`);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const fetchSingleOrganization = async () => {
        try {
            setIsPageLoading(true);
            const response = await axios.get(
                `/admin/organizations/single/${id}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setData((prev) => {
                return {
                    ...prev,
                    organizationName: response.data?.organizationName,
                };
            });
            setIsPageLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchSingleOrganization();
    }, []);

    if (isPageLoading) {
        return <Loader />;
    }

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Edit Organization</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="">Organization Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Ex: Sky Travels"
                        name="organizationName"
                        value={data.organizationName}
                        onChange={handleChange}
                        required
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Edit Organization"}
                </button>
            </form>
        </div>
    );
}
