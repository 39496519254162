import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import {
    handleChildrenAgeChange,
    handleNoOfAdultsChange,
    handleNoOfChildrenChange,
    setDateError,
    handleCheckInChange,
    handleCheckOutChange,
    handleClientNameChange,
} from "../../../redux/slices/quotationsSlice";

export default function QuotationPax() {
    const [noOfNights, setnoOfNights] = useState("");
    const {
        noOfAdults,
        noOfChildren,
        childrenAges,
        dateError,
        checkInDate,
        checkOutDate,
        clientName,
    } = useSelector((state) => state.quotations);
    const dispatch = useDispatch();

    useEffect(() => {
        if (checkInDate && checkOutDate) {
            const convertedCheckOutDate = new Date(checkOutDate);
            const convertedCheckInDate = new Date(checkInDate);
            const diffTime = Math.abs(
                convertedCheckOutDate - convertedCheckInDate
            );
            setnoOfNights(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
            if (convertedCheckOutDate < convertedCheckInDate) {
                dispatch(
                    setDateError("Checkout date must be after checkin date")
                );
            } else {
                dispatch(setDateError(""));
            }
        }
    }, [checkInDate, checkOutDate]);

    const handleNoOfNightsChange = (e) => {
        if (checkInDate && e.target.value) {
            const convertedCheckInDate = new Date(checkInDate);
            const convertedCheckOutDate = new Date(
                convertedCheckInDate.setDate(
                    convertedCheckInDate.getDate() + Number(e.target.value)
                )
            );
            dispatch(handleCheckOutChange(convertedCheckOutDate));
            if (convertedCheckOutDate < convertedCheckInDate) {
                dispatch(
                    setDateError("Checkout date must be after checkin date")
                );
            } else {
                dispatch(setDateError(""));
            }
        } else if (checkOutDate && e.target.value) {
            const convertedCheckOutDate = new Date(checkOutDate);
            const convertedCheckInDate = new Date(
                convertedCheckOutDate.setDate(
                    convertedCheckOutDate.getDate() - Number(e.target.value)
                )
            );
            dispatch(handleCheckInChange(convertedCheckInDate));
            if (convertedCheckOutDate < convertedCheckInDate) {
                dispatch(
                    setDateError("Checkout date must be after checkin date")
                );
            } else {
                dispatch(setDateError(""));
            }
        }
        setnoOfNights(e.target.value);
    };

    const handleDateChange = () => {
        if (checkInDate && checkOutDate) {
            const convertedCheckOutDate = new Date(checkOutDate);
            const convertedCheckInDate = new Date(checkInDate);
            const diffTime = Math.abs(
                convertedCheckOutDate - convertedCheckInDate
            );
            setnoOfNights(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
            if (convertedCheckOutDate < convertedCheckInDate) {
                dispatch(
                    setDateError("Checkout date must be after checkin date")
                );
            } else {
                dispatch(setDateError(""));
            }
        }
    };

    return (
        <div>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Client Name
                </label>
                <input
                    type="string"
                    value={clientName || ""}
                    onChange={(e) =>
                        dispatch(handleClientNameChange(e.target.value))
                    }
                    placeholder="Enter No of Adults"
                />
            </div>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    No Of Adults
                </label>
                <input
                    type="number"
                    value={noOfAdults || ""}
                    onChange={(e) =>
                        dispatch(handleNoOfAdultsChange(e.target.value))
                    }
                    placeholder="Enter No of Adults"
                    onWheel={(e) => e.target.blur()}
                />
            </div>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    No Of Children
                </label>
                <input
                    type="number"
                    value={noOfChildren || ""}
                    onChange={(e) =>
                        dispatch(handleNoOfChildrenChange(e.target.value))
                    }
                    placeholder="Enter no of Children"
                    onWheel={(e) => e.target.blur()}
                />
            </div>
            {childrenAges && childrenAges?.length > 0 && (
                <div className="flex items-start gap-[2em] mt-6">
                    <label htmlFor="" className="w-[100%] max-w-[180px]">
                        Children Ages
                    </label>
                    <div className="flex items-center flex-wrap gap-[10px]">
                        {childrenAges?.map((age, index) => {
                            return (
                                <select
                                    name=""
                                    className="w-[100px]"
                                    value={age || ""}
                                    key={index}
                                    onChange={(e) =>
                                        dispatch(
                                            handleChildrenAgeChange({
                                                index,
                                                value: e.target.value,
                                            })
                                        )
                                    }
                                >
                                    <option value="" hidden>
                                        None
                                    </option>
                                    {Array.from({ length: 18 }).map(
                                        (_, arrIndex) => {
                                            return (
                                                <option
                                                    value={`${arrIndex} yrs`}
                                                    key={arrIndex}
                                                >
                                                    {arrIndex} - {arrIndex + 1}{" "}
                                                    yrs
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Check In Date
                </label>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    selected={checkInDate ? new Date(checkInDate) : ""}
                    onChange={(date) => {
                        handleDateChange();
                        dispatch(handleCheckInChange(date));
                    }}
                    minDate={new Date()}
                    className="w-[100%] h-[40px]"
                />
            </div>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    No of nights
                </label>
                <input
                    type="number"
                    placeholder="Enter number no of nights"
                    onChange={handleNoOfNightsChange}
                    value={noOfNights || ""}
                    onWheel={(e) => e.target.blur()}
                />
            </div>
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Check Out Date
                </label>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    selected={checkOutDate ? new Date(checkOutDate) : ""}
                    onChange={(date) => {
                        handleDateChange();
                        dispatch(handleCheckOutChange(date));
                    }}
                    minDate={
                        checkInDate
                            ? new Date(
                                  new Date(checkInDate).setDate(
                                      new Date(checkInDate).getDate() + 1
                                  )
                              )
                            : new Date()
                    }
                />
            </div>
            {dateError && (
                <span className="text-red-500 text-sm block mt-4">
                    {dateError}
                </span>
            )}
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Package :{" "}
                    <span className="font-medium">
                        {noOfNights
                            ? "" + noOfNights + "N " + (noOfNights + 1) + "D"
                            : "N/A"}
                    </span>
                </label>
            </div>
        </div>
    );
}
