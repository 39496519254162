import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader, Loader } from "../../../components";

export default function ExcursionEditPage() {
    const [data, setData] = useState({
        excursionName: "",
        description: "",
        excursionType: "transfer",
        price1: "",
        price2: "",
        price3: "",
        price4: "",
        price5: "",
        price6: "",
        price7: "",
        price8: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { id } = useParams();

    const onDataChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            await axios.patch(`/admin/excursions/update/${id}`, data, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            navigate("/admin/excursions");
        } catch (error) {
            setError(
                error?.response?.data?.error ||
                    "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const fetchExcursion = useCallback(async () => {
        try {
            setIsFetching(true);

            const { data } = await axios.get(`/admin/excursions/single/${id}`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setData((prev) => {
                return {
                    ...prev,
                    excursionName: data?.excursionName,
                    description: data?.description,
                    excursionType: data?.excursionType || "sic",
                    price1:
                        data?.excursionType === "sic"
                            ? data?.sicPricing?.sicPrice
                            : data?.excursionType === "ticket"
                            ? data?.ticketPricing?.adultPrice
                            : data?.excursionType === "transfer"
                            ? data?.transferPricing?.sevenSeaterPrice
                            : data?.excursionType === "regular"
                            ? data?.regularPricing?.price
                            : "",
                    price2:
                        data?.excursionType === "sic"
                            ? data?.sicPricing?.sevenSeaterPrice
                            : data?.excursionType === "ticket"
                            ? data?.ticketPricing?.childPrice
                            : data?.excursionType === "transfer"
                            ? data?.transferPricing?.fourteenSeaterPrice
                            : "",
                    price3:
                        data?.excursionType === "transfer"
                            ? data?.transferPricing?.sicPrice
                            : data?.excursionType === "ticket"
                            ? data?.ticketPricing?.sicWithTicketAdultPrice
                            : "",
                    price4:
                        data?.excursionType === "ticket"
                            ? data?.ticketPricing?.sicWithTicketChildPrice
                            : "",
                    price5:
                        data?.excursionType === "ticket"
                            ? data?.ticketPricing
                                  ?.privateTransferSevenSeaterPriceAdult
                            : "",
                    price6:
                        data?.excursionType === "ticket"
                            ? data?.ticketPricing
                                  ?.privateTransferSevenSeaterPriceChild
                            : "",
                    price7:
                        data?.excursionType === "ticket"
                            ? data?.ticketPricing
                                  ?.privateTransferFourteenSeaterPriceAdult
                            : "",
                    price8:
                        data?.excursionType === "ticket"
                            ? data?.ticketPricing
                                  ?.privateTransferFourteenSeaterPriceChild
                            : "",
                };
            });
            setIsFetching(false);
        } catch (err) {
            console.log(err);
        }
    }, [id, jwtToken]);

    useEffect(() => {
        fetchExcursion();
    }, [fetchExcursion]);

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Edit Excursion</h2>
            <form onSubmit={submitHandler}>
                <div className="name mb-5">
                    <label htmlFor="input-name">Excursion Name</label>
                    <input
                        type="text"
                        placeholder="Enter name"
                        id="input-name"
                        name="excursionName"
                        value={data.excursionName || ""}
                        onChange={onDataChange}
                        required
                    />
                </div>

                <div className="descriptions mb-5">
                    <label htmlFor="input-descriptions">Descriptions</label>
                    <textarea
                        placeholder="Enter descriptions"
                        id="input-descriptions"
                        name="description"
                        onChange={onDataChange}
                        value={data?.description || ""}
                    />
                </div>

                <div className="descriptions mb-5">
                    <label htmlFor="input-descriptions">Excursion Type</label>
                    <select
                        className="w-full"
                        name="excursionType"
                        onChange={(e) => {
                            setData((prev) => {
                                return {
                                    ...prev,
                                    excursionType: e.target.value,
                                    price1: "",
                                    price2: "",
                                    price3: "",
                                };
                            });
                        }}
                        value={data?.excursionType || ""}
                        required
                    >
                        <option value={"ticket"}>Ticket</option>
                        <option value={"transfer"}>Transfer</option>
                        {/* <option value={"regular"}>Regular</option> */}
                    </select>
                </div>

                <div>
                    <div className="price mb-5">
                        <label htmlFor="input-price">
                            {data.excursionType === "sic"
                                ? "Seat in coach"
                                : data.excursionType === "ticket"
                                ? "Adult"
                                : data.excursionType === "transfer"
                                ? "7 Seater"
                                : "price"}
                        </label>
                        <input
                            type="number"
                            placeholder="Enter price"
                            id="input-price"
                            name="price1"
                            value={data.price1}
                            onChange={onDataChange}
                            required={data.excursionType !== "transfer"}
                        />
                    </div>

                    {data?.excursionType !== "regular" && (
                        <div className="price mb-5">
                            <label htmlFor="input-price">
                                {data.excursionType === "sic"
                                    ? "7 Seater"
                                    : data.excursionType === "ticket"
                                    ? "child"
                                    : "14 Seater "}
                            </label>
                            <input
                                type="number"
                                placeholder="Enter price"
                                id="input-price"
                                name="price2"
                                value={data.price2}
                                onChange={onDataChange}
                                required={data.excursionType !== "transfer"}
                            />
                        </div>
                    )}

                    {(data.excursionType === "transfer" ||
                        data.excursionType === "ticket") && (
                        <div className="price mb-5">
                            <label htmlFor="input-price">
                                {data?.excursionType === "transfer"
                                    ? "SIC"
                                    : "Sic With Ticket Adult"}
                            </label>
                            <input
                                type="number"
                                placeholder="Enter price"
                                id="input-price"
                                name="price3"
                                value={data.price3}
                                onChange={onDataChange}
                                required={data.excursionType === "sic"}
                            />
                        </div>
                    )}

                    {data.excursionType === "ticket" && (
                        <>
                            <div className="price mb-5">
                                <label htmlFor="input-price">
                                    Sic With Ticket Child
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter price"
                                    id="input-price"
                                    name="price4"
                                    value={data.price4}
                                    onChange={onDataChange}
                                />
                            </div>
                            <div className="price mb-5">
                                <label htmlFor="input-price">
                                    Private Transfer 7 Seater Adult
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter price"
                                    id="input-price"
                                    name="price5"
                                    value={data.price5}
                                    onChange={onDataChange}
                                />
                            </div>
                            <div className="price mb-5">
                                <label htmlFor="input-price">
                                    Private Transfer 7 Seater Child
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter price"
                                    id="input-price"
                                    name="price6"
                                    value={data.price6}
                                    onChange={onDataChange}
                                />
                            </div>
                            <div className="price mb-5">
                                <label htmlFor="input-price">
                                    Private Transfer 14 Seater Adult
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter price"
                                    id="input-price"
                                    name="price7"
                                    value={data.price7}
                                    onChange={onDataChange}
                                />
                            </div>
                            <div className="price mb-5">
                                <label htmlFor="input-price">
                                    Private Transfer 14 Seater Child
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter price"
                                    id="input-price"
                                    name="price8"
                                    value={data.price8}
                                    onChange={onDataChange}
                                />
                            </div>
                        </>
                    )}
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-full" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Update"}
                </button>
            </form>
        </div>
    );
}
