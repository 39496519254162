import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { MdContentCopy } from "react-icons/md";

import { logoImg } from "../../assets/images";
import axios from "../../axios";
import { Loader } from "../../components";

export default function SingleQuotationEmailPage() {
    const [quotation, setQuotation] = useState({});
    const [isCopied, setIsCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { quotationNumber } = useParams();
    const { jwtToken, user } = useSelector((state) => state.user);
    const { roomOccupancies, initialDataFetching } = useSelector(
        (state) => state.quotations
    );

    const copyDocument = () => {
        const el = document.getElementById("email-wrapper");
        let range, sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            range.selectNodeContents(el);
            sel.addRange(range);
        }
        document.execCommand("Copy");
        setIsCopied(true);
    };

    const getQuotationsDetails = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get(
                `/quotations/single/${quotationNumber}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setQuotation(data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [jwtToken, quotationNumber]);

    useEffect(() => {
        getQuotationsDetails();
    }, [getQuotationsDetails]);

    useEffect(() => {
        let timeout;
        if (isCopied) {
            timeout = setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        }

        return () => clearTimeout(timeout);
    }, [isCopied]);

    if (isLoading || initialDataFetching) {
        return <Loader />;
    }

    return (
        <div className="p-6 " style={{ lineHeight: "26px", fontSize: "14px" }}>
            <div className="flex items-center justify-end gap-[15px] mb-6">
                <button
                    onClick={copyDocument}
                    className="px-3 flex items-center gap-[10px]"
                >
                    <MdContentCopy />
                    {isCopied ? "Copied" : "Copy"}
                </button>
                <a
                    href={
                        process.env.REACT_APP_SERVER_URL +
                        quotation.amendment?.pdf
                    }
                    target="blank"
                >
                    <button className="flex items-center gap-[10px] px-3">
                        <FiDownload />
                        Download Pdf
                    </button>
                </a>
                <a
                    href={
                        process.env.REACT_APP_SERVER_URL +
                        quotation.amendment?.sheet
                    }
                    download
                >
                    <button className="flex items-center gap-[10px] px-3">
                        <FiDownload />
                        Download Sheet
                    </button>
                </a>
            </div>
            <div
                id="email-wrapper"
                style={{ lineHeight: "26px" }}
                className="callibri-font"
            >
                <span
                    className="cust-border"
                    style={{ textTransform: "capitalize" }}
                >
                    Dear {quotation?.amendment?.clientName},
                </span>
                <br />
                <br />
                <span>
                    <span
                        style={{ fontWeight: 600, lineHeight: "26px" }}
                        className="cust-border"
                    >
                        Greetings from Travellers Choice!!!!
                    </span>
                </span>
                <br />
                <span className="cust-border" style={{ lineHeight: "26px" }}>
                    Kindly find the below quote for your reference.
                </span>
                <br />
                <br />
                <div className="cust-border" style={{ lineHeight: "26px" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                    }}
                                    className="cust-border"
                                >
                                    Quotation Number
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    :
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    {user?.shortCode}-
                                    {quotation?.quotationNumber}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                    }}
                                    className="cust-border"
                                >
                                    Total Pax
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    :
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    {quotation?.amendment?.noOfAdults
                                        ? quotation?.amendment?.noOfAdults +
                                          " Adult"
                                        : ""}
                                    {quotation?.amendment?.noOfAdults &&
                                    quotation?.amendment?.noOfChildren
                                        ? ", "
                                        : " "}
                                    {quotation?.amendment?.noOfChildren
                                        ? quotation?.amendment?.noOfChildren +
                                          " Child"
                                        : ""}
                                </td>
                            </tr>
                            {quotation?.amendment?.transferQuotation && (
                                <tr>
                                    <td
                                        style={{
                                            paddingRight: "10px",
                                            paddingTop: "2px",
                                            paddingBottom: "2px",
                                        }}
                                        className="cust-border"
                                    >
                                        Destination
                                    </td>
                                    <td
                                        style={{
                                            paddingRight: "10px",
                                            paddingLeft: "10px",
                                        }}
                                        className="cust-border"
                                    >
                                        :
                                    </td>
                                    <td
                                        style={{
                                            paddingRight: "10px",
                                            paddingLeft: "10px",
                                        }}
                                        className="cust-border"
                                    >
                                        {
                                            quotation?.amendment
                                                ?.transferQuotation
                                                ?.transferToName
                                        }
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                    }}
                                    className="cust-border"
                                >
                                    Package
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    :
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    {quotation?.amendment?.noOfNights}N /{" "}
                                    {quotation?.amendment?.noOfNights + 1}D
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                    }}
                                    className="cust-border"
                                >
                                    Check In
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    :
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    {new Date(
                                        quotation?.amendment?.checkInDate
                                    ).toDateString()}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                    }}
                                    className="cust-border"
                                >
                                    Check Out
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    :
                                </td>
                                <td
                                    style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                    }}
                                    className="cust-border"
                                >
                                    {new Date(
                                        quotation?.amendment?.checkOutDate
                                    ).toDateString()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />

                {quotation?.amendment?.hotelQuotation ? (
                    <div>
                        <table
                            style={{
                                width: "100%",
                                textAlign: "left",
                                lineHeight: "26px",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            border: "1px solid gray",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            paddingTop: "5px",
                                            paddingBottom: "5px",
                                        }}
                                    >
                                        Star Category
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            border: "1px solid gray",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        Name of Hotel
                                    </th>
                                    <th
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            border: "1px solid gray",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        Location
                                    </th>
                                    {roomOccupancies?.map(
                                        (roomOccupancy, index) => {
                                            return (
                                                <th
                                                    key={index}
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        border: "1px solid gray",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    {
                                                        roomOccupancy?.roomOccupancyShortName
                                                    }
                                                </th>
                                            );
                                        }
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {quotation?.amendment?.hotelQuotation?.hotels?.map(
                                    (hotel, index) => {
                                        return (
                                            <tr key={index}>
                                                <td
                                                    style={{
                                                        border: "1px solid gray",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                        paddingTop: "5px",
                                                        paddingBottom: "5px",
                                                    }}
                                                >
                                                    {hotel?.starCategory
                                                        ? hotel?.starCategory
                                                        : "N/A"}
                                                </td>
                                                <td
                                                    style={{
                                                        border: "1px solid gray",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    {hotel?.hotelName}
                                                    <br />
                                                    {hotel?.roomTypeName && (
                                                        <>
                                                            <span className="">
                                                                *{" "}
                                                                {
                                                                    hotel?.roomTypeName
                                                                }
                                                            </span>
                                                            <br />
                                                        </>
                                                    )}
                                                    <span className="">
                                                        *{" "}
                                                        {hotel.isBreakfastIncluded
                                                            ? "Breakfast Included"
                                                            : "Room Only"}
                                                    </span>
                                                </td>
                                                <td
                                                    style={{
                                                        border: "1px solid gray",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    {hotel?.placeName || "N/A"}
                                                </td>
                                                {hotel?.roomOccupancies?.map(
                                                    (roomOccupancy, index) => {
                                                        return (
                                                            <td
                                                                key={index}
                                                                style={{
                                                                    border: "1px solid gray",
                                                                    paddingLeft:
                                                                        "10px",
                                                                    paddingRight:
                                                                        "10px",
                                                                }}
                                                            >
                                                                {roomOccupancy?.perPersonTotalPrice
                                                                    ? Math.ceil(
                                                                          roomOccupancy?.perPersonTotalPrice
                                                                      ) +
                                                                      " " +
                                                                      quotation
                                                                          ?.amendment
                                                                          ?.quotationCurrency +
                                                                      " PP"
                                                                    : "N/A"}
                                                            </td>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>
                        {quotation?.amendment?.hotelDisabledRemark && (
                            <>
                                <span
                                    style={{ marginBottom: "2px" }}
                                    className="cust-border"
                                >
                                    *{" "}
                                    {quotation?.amendment?.hotelDisabledRemark}
                                </span>
                                <br />
                                <br />
                            </>
                        )}

                        <div
                            className="cust-border"
                            style={{ lineHeight: "26px" }}
                        >
                            Per person Adult price :{" "}
                            <span style={{ fontWeight: 500 }}>
                                {Math.ceil(
                                    quotation?.amendment?.perPersonAdultPrice
                                )}{" "}
                                {quotation?.amendment?.quotationCurrency} PP
                            </span>
                        </div>
                        {Number(quotation?.amendment?.noOfChildren) > 0 ? (
                            <div
                                className="cust-border mt-1"
                                style={{ lineHeight: "26px" }}
                            >
                                Per person Child price :{" "}
                                <span style={{ fontWeight: 500 }}>
                                    {Math.ceil(
                                        quotation?.amendment
                                            ?.perPersonChildPrice
                                    )}{" "}
                                    {quotation?.amendment?.quotationCurrency} PP
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                )}
                <br />
                <div className="cust-border">
                    <h2
                        style={{ fontWeight: 600, marginBottom: "4px" }}
                        className="cust-border"
                    >
                        Inclusions
                    </h2>
                    <ul
                        style={{
                            listStyle: "disc",
                            padding: "0px",
                            margin: "0px",
                            lineHeight: "26px",
                        }}
                        className="cust-border"
                    >
                        {quotation?.amendment?.excursionQuotation?.excursions?.map(
                            (excursion, index) => {
                                return (
                                    <li
                                        key={index}
                                        style={{ marginBottom: "2px" }}
                                        className="cust-border"
                                    >
                                        {excursion?.excursionName} -{" "}
                                        <span className="capitalize">
                                            {excursion?.excursionType ===
                                            "ticket"
                                                ? excursion?.value === "ticket"
                                                    ? "Only Ticket"
                                                    : excursion?.value ===
                                                      "shared"
                                                    ? "Tickets With SIC Transfer"
                                                    : excursion?.value ===
                                                      "private"
                                                    ? "Tickets With PVT Transfer"
                                                    : ""
                                                : excursion?.excursionType ===
                                                  "transfer"
                                                ? excursion?.value === "private"
                                                    ? "Only Transfer (Private)"
                                                    : excursion?.value ===
                                                      "shared"
                                                    ? "Only Transfer (SIC)"
                                                    : ""
                                                : ""}
                                        </span>
                                    </li>
                                );
                            }
                        )}
                        {quotation?.amendment?.transferQuotation && (
                            <li className="cust-border">
                                Airport Transfer included -{" "}
                                {quotation?.amendment?.transferQuotation
                                    ?.isReturn
                                    ? "Return transfer"
                                    : "One way transfer"}{" "}
                                {` from ${quotation?.amendment?.transferQuotation?.transferFromName} to ${quotation?.amendment?.transferQuotation?.transferToName}`}
                            </li>
                        )}
                        {quotation?.amendment?.visa?.price && (
                            <li className="cust-border">Visa included</li>
                        )}
                        <li>All prices inclusive of VAT</li>
                        {quotation?.amendment?.hotelQuotation
                            ?.isTourismFeeIncluded === true && (
                            <li>Tourism dirham fee inclusive</li>
                        )}
                    </ul>
                </div>
                <br />
                {quotation?.amendment?.excSupplementQuotation && (
                    <>
                        <div>
                            <h2
                                style={{ fontWeight: 600, marginBottom: "4px" }}
                                className="cust-border"
                            >
                                Supplements
                            </h2>

                            <ul
                                style={{
                                    listStyle: "disc",
                                    padding: "0px",
                                    margin: "0px",
                                    lineHeight: "26px",
                                }}
                                className="cust-border"
                            >
                                {quotation?.amendment?.excSupplementQuotation?.excursions?.map(
                                    (excursion, index) => {
                                        return (
                                            <li
                                                key={index}
                                                style={{ marginBottom: "2px" }}
                                                className="cust-border"
                                            >
                                                {excursion?.excursionName} -{" "}
                                                <span className="capitalize">
                                                    {excursion?.excursionType ===
                                                    "ticket"
                                                        ? excursion?.value ===
                                                          "ticket"
                                                            ? "Only Ticket"
                                                            : excursion?.value ===
                                                              "shared"
                                                            ? "Tickets With SIC Transfer"
                                                            : excursion?.value ===
                                                              "private"
                                                            ? "Tickets With PVT Transfer"
                                                            : ""
                                                        : excursion?.excursionType ===
                                                          "transfer"
                                                        ? excursion?.value ===
                                                          "private"
                                                            ? "Only Transfer (Private)"
                                                            : excursion?.value ===
                                                              "shared"
                                                            ? "Only Transfer (SIC)"
                                                            : ""
                                                        : ""}
                                                </span>
                                                <span>
                                                    {" "}
                                                    - (Adult -{" "}
                                                    {quotation?.amendment
                                                        ?.quotationCurrency ===
                                                    "AED"
                                                        ? excursion?.adultPrice
                                                        : (
                                                              excursion?.adultPrice /
                                                              3.65
                                                          )?.toFixed(0)}{" "}
                                                    {
                                                        quotation?.amendment
                                                            ?.quotationCurrency
                                                    }
                                                    , Child -{" "}
                                                    {quotation?.amendment
                                                        ?.quotationCurrency ===
                                                    "AED"
                                                        ? excursion?.childPrice
                                                        : (
                                                              excursion?.childPrice /
                                                              3.65
                                                          )?.toFixed(0)}{" "}
                                                    {
                                                        quotation?.amendment
                                                            ?.quotationCurrency
                                                    }
                                                    )
                                                </span>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                        <br />
                    </>
                )}

                <div className="cust-border">
                    <h2
                        style={{ fontWeight: 600, marginBottom: "4px" }}
                        className="cust-border"
                    >
                        Terms and Conditions
                    </h2>
                    <ul
                        style={{
                            listStyle: "disc",
                            padding: "0px",
                            margin: "0px",
                            lineHeight: "26px",
                        }}
                        className="cust-border"
                    >
                        {quotation?.amendment?.hotelQuotation
                            ?.isTourismFeeIncluded === false && (
                            <li
                                style={{
                                    marginBottom: "2px",
                                }}
                                className="cust-border"
                            >
                                Tourism Dirham Fee will pay by guest directly
                                upon Check-in At Above Mentioned Hotels
                            </li>
                        )}
                        {!quotation?.amendment?.hotelQuotation?.hotels && (
                            <li
                                style={{ marginBottom: "2px" }}
                                className="cust-border"
                            >
                                Hotel location should be Bur Dubai Deira only
                                For SIC Transfer & Tours.
                            </li>
                        )}
                        <li className="cust-border">
                            All the above package cost is quoted in{" "}
                            {quotation?.amendment?.quotationCurrency} per person
                            and is valid till{" "}
                            {new Date(
                                new Date(
                                    quotation?.amendment?.createdAt
                                ).setDate(
                                    new Date(
                                        quotation?.amendment?.createdAt
                                    ).getDate() + 2
                                )
                            ).toDateString()}
                        </li>
                        <li className="cust-border">
                            The above rates are subject to change as per hotel's
                            inputs. This could be due to withdrawal of
                            promotional by the Hotel or currency fluctuation or
                            any additional taxes or toll implemented by the
                            Government.
                        </li>
                        <li className="cust-border">
                            Accommodation for Child as stated is as per the
                            child policy of the respective Hotel. Child below 02
                            Years Is considered as an infant and from 02 years
                            to 12 years as a child.
                        </li>
                        <li className="cust-border">
                            Above package rate is subject to availability of
                            rooms and offered inclusions and is subject to
                            change as per availability at the time of booking.
                            Kindly reconfirm with our team before confirming to
                            the client.
                        </li>
                        <li className="cust-border">
                            Cancellation charges are applicable as per the Hotel
                            policy. NRF rates are subject to 100% cancellation
                            charges.
                        </li>
                        <li className="cust-border">
                            All the services included in the package are
                            compulsory and no refund will be given if any of the
                            services are not taken.
                        </li>
                        <li className="cust-border">
                            Tourism Dirham which has been levied by the hotels
                            in Dubai, same has to be paid directly by the guest
                            at the hotel upon check in.
                        </li>
                        <li className="cust-border">
                            Guest need to carry QR code vaccine certificate &
                            required to provide a negative PCR test result
                            acquired within 48 hours in Dubai only , to enter
                            Grand Mosque/Ferrari world Or any Mall visit at Abu
                            Dhabi.
                        </li>
                        <li className="cust-border">
                            PCR cost Not included in above rates
                        </li>
                        <li className="cust-border">
                            Please Note the Check In time is 3:00 PM And check
                            out time Is 12:00 PM. Early Check In or Late Check
                            Out Is depending upon Hotel room availability and
                            may be subject to an extra charge.
                        </li>
                        <li className="cust-border">
                            Rooms And Rates Are Subject To Availability At The
                            Time Of Booking Confirmation kindly reconfirm before
                            the booking.
                        </li>
                    </ul>
                </div>
                <br />
                <div className="cust-border">
                    <span
                        style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "26px",
                        }}
                        className="cust-border"
                    >
                        {quotation?.agent?.name} | {quotation?.agent?.position}
                    </span>
                    <br />
                    <span
                        style={{ fontSize: "14px", lineHeight: "26px" }}
                        className="cust-border"
                    >
                        Email:{" "}
                        <span
                            style={{
                                color: "blue",
                                textDecoration: "underline",
                            }}
                            className="cust-border"
                        >
                            {quotation?.agent?.email}
                        </span>
                    </span>
                    <br />
                    <span
                        style={{ marginTop: "20px", lineHeight: "26px" }}
                        className="cust-border"
                    >
                        Mobile/Whatsapp: {quotation?.agent?.phoneNumber || ""} |
                        Tel: {quotation?.agent?.telephoneNumber || ""} Ext.{" "}
                        {quotation?.agent?.extensionCode || ""}
                    </span>
                    <br />
                    <span
                        className="cust-border"
                        style={{ lineHeight: "26px" }}
                    >
                        Dubai | Ahmedabad | Kenya | Delhi
                    </span>
                    <br />
                    <span
                        className="cust-border"
                        style={{ lineHeight: "26px" }}
                    >
                        Website: www.travellerschoice.ae B2B Agent Login:
                        https://app.mytcb2b.com/
                    </span>
                    <br />
                    <img
                        width="150"
                        src={logoImg}
                        alt=""
                        style={{ marginTop: "15px" }}
                    />
                </div>
            </div>
        </div>
    );
}
