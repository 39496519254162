import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Loader, Pagination } from "../../components";
import getFormatedDate from "../../utils/getFormatedDate";
import {
    clearQuotationsFilters,
    fetchAgentsQuotations,
    incOrDecQuotationsSkip,
    updateIsQuotationsLoading,
    updateQuotationsFilters,
    updateQuotationsSkip,
} from "../../redux/slices/quotationsListSlice";

export default function QuotationsListPage() {
    const { quotations, filters, skip, limit, totalQuotations, isLoading } =
        useSelector((state) => state.quotationsList);
    const { user } = useSelector((state) => state.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFilterChange = (e) => {
        dispatch(
            updateQuotationsFilters({
                name: e.target.name,
                value: e.target.value,
            })
        );
    };

    useEffect(() => {
        dispatch(updateIsQuotationsLoading(true));
        dispatch(fetchAgentsQuotations());
    }, [skip, limit, dispatch]);

    return (
        <div className="">
            <div className="mb-7 flex flex-wrap  justify-between">
                <h1 className="text-lg font-[600]">Quotations</h1>

                <div className="flex items-end justify-end gap-[10px]">
                    <div>
                        <label htmlFor="">From</label>
                        <input
                            type="date"
                            name="dateFrom"
                            onChange={handleFilterChange}
                            value={filters?.dateFrom || ""}
                        />
                    </div>
                    <div>
                        <label htmlFor="">To</label>
                        <input
                            type="date"
                            name="dateTo"
                            onChange={handleFilterChange}
                            value={filters?.dateTo || ""}
                        />
                    </div>

                    <div>
                        <label htmlFor="">Search</label>
                        <input
                            type="text"
                            placeholder="Quotation Number.."
                            name="quotationNumber"
                            value={filters?.quotationNumber || ""}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <button
                        className="px-3"
                        onClick={() => {
                            dispatch(updateIsQuotationsLoading(true));
                            dispatch(fetchAgentsQuotations());
                        }}
                    >
                        Search
                    </button>
                    <button
                        className="px-3 bg-slate-200 text-[#222]"
                        onClick={() => {
                            dispatch(clearQuotationsFilters());
                            dispatch(updateIsQuotationsLoading(true));
                            dispatch(fetchAgentsQuotations());
                        }}
                    >
                        Clear
                    </button>
                </div>
            </div>

            {isLoading ? (
                <Loader />
            ) : quotations?.length < 1 ? (
                <span className="text-center block text-sm text-gray-500 font-medium mt-10">
                    Quotaions Not found
                </span>
            ) : (
                <>
                    <table className="w-full text-sm text-left text-gray-500 shadow">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                            <tr>
                                <th scope="col" className="py-3 px-[12px]">
                                    Quotation Number
                                </th>
                                <th scope="col" className="py-3 px-[12px]">
                                    Creation Date
                                </th>
                                <th className="py-3 px-[12px]">Updated Date</th>
                                <th className="py-3 px-[12px]">
                                    Total Amendments
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {quotations?.map((qtn, index) => (
                                <tr
                                    key={index}
                                    className="bg-white border-b hover:bg-gray-50 hover:cursor-pointer"
                                    onClick={() =>
                                        navigate(
                                            `/quotations/${qtn.quotationNumber}`
                                        )
                                    }
                                >
                                    <td className="py-3 px-[12px]">
                                        {user?.shortCode}-{qtn?.quotationNumber}
                                    </td>
                                    <td className="py-3 px-[12px]">
                                        {getFormatedDate(qtn?.createdAt)}
                                    </td>
                                    <td className="py-3 px-[12px]">
                                        {getFormatedDate(qtn?.updatedAt)}
                                    </td>
                                    <td className="py-3 px-[12px]">
                                        {qtn?.totalAmendments}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination
                        total={totalQuotations}
                        skip={skip}
                        limit={limit}
                        incOrDecSkip={(number) =>
                            dispatch(incOrDecQuotationsSkip(number))
                        }
                        updateSkip={(skip) =>
                            dispatch(updateQuotationsSkip(skip))
                        }
                    />
                </>
            )}
        </div>
    );
}
