import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Toggle from "../../../components/Toggle";
import axios from "../../../axios";
import { BiEditAlt } from "react-icons/bi";

export default function AgentsTableSingleRow({ agent, agents, setAgents }) {
    const [isActive, setIsActive] = useState(!agent?.isActive);

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const handleDelete = async (id) => {
        try {
            const isConfirm = window.confirm("Are you sure to delete?");
            if (isConfirm) {
                await axios.delete(`/admin/agents/delete/${id}`, {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                });
                const filteredItems = agents.filter((item) => {
                    return item?._id !== id;
                });
                setAgents(filteredItems);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleIsActiveChange = async () => {
        try {
            await axios.patch(
                `/admin/agents/update/is-active/${agent?._id}`,
                {
                    isActive: isActive,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setIsActive(!isActive);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <tr
            className="bg-white border-b hover:bg-gray-50 cursor-pointer"
            onClick={() => {
                navigate(`${agent?._id}/quotations`);
            }}
        >
            <td className="py-3 px-3">{agent?.shortCode}</td>
            <td className="py-3 px-3">{agent?.name}</td>
            <td className="py-3 px-3">{agent?.email}</td>
            <td className="py-3 px-3">{agent?.position || "N/A"}</td>
            <td className="py-3 px-3">
                <div className="relative" onClick={(e) => e.stopPropagation()}>
                    <Toggle
                        defaultChecked={isActive}
                        handleToggleChange={handleIsActiveChange}
                    />
                </div>
            </td>
            <td className="py-3 px-3" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center gap-[15px]">
                    <Link to={`edit/${agent?._id}`}>
                        <button className="flex items-center justify-center h-auto p-0 text-green-500 text-xl bg-transparent">
                            <BiEditAlt />
                        </button>
                    </Link>
                    <button
                        onClick={() => {
                            handleDelete(agent?._id);
                        }}
                        className="p-0 h-auto text-red-500 bg-transparent text-xl"
                    >
                        <MdDelete />
                    </button>
                </div>
            </td>
        </tr>
    );
}
