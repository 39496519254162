import React, { useState } from "react";
import TransferMarkupModal from "./TransferMarkupModal";

export default function SingleTransferTableRow({ transfer }) {
    const [markup, setMarkup] = useState(transfer?.markup);
    const [isMarkupModalOpen, setIsMarkupModalOpen] = useState(false);

    return (
        <tr className="bg-white border-b ">
            <td className="py-3 px-3">{transfer?.transferFrom?.name} </td>
            <td className="py-3 px-3">{transfer?.transferTo?.name} </td>
            <td className="py-3 px-3">{transfer.price1} AED</td>
            <td className="py-3 px-3">{transfer.price2} AED</td>
            <td className="py-3 px-3">
                <div className="flex items-center gap-[10px]">
                    <span>
                        {markup?.markup
                            ? `${markup?.markup}${
                                  markup?.markupType === "flat" ? " AED" : " %"
                              }`
                            : "N/A"}
                    </span>
                    <span
                        className="flex items-center gap-[15px] text-blue-500 underline cursor-pointer"
                        onClick={() => setIsMarkupModalOpen(true)}
                    >
                        Edit
                    </span>
                </div>
                {isMarkupModalOpen && (
                    <TransferMarkupModal
                        markup={markup}
                        setMarkup={setMarkup}
                        setIsMarkupModalOpen={setIsMarkupModalOpen}
                        transfer={transfer?._id}
                    />
                )}
            </td>
        </tr>
    );
}
