import { useCallback, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ThemeRoutes } from "./routes";
import axios from "./axios";
import { setCountiesAndStates, updateUser } from "./redux/slices/userSlices";

export default function App() {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    const fetchUser = useCallback(async () => {
        try {
            const token = localStorage.getItem("token");
            if (token) {
                const response = await axios.get("/users/my-account", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                dispatch(updateUser(response.data));
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }, [dispatch]);

    const fetchCountriesAndStates = async () => {
        try {
            const response = await axios.get("/countries/all");
            dispatch(setCountiesAndStates(response.data));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        fetchCountriesAndStates();
    }, []);

    const routing = useRoutes(ThemeRoutes);
    return isLoading ? (
        <div className="flex items-center justify-center min-h-[100vh]">
            <div className="w-[30px] h-[30px] rounded-full border-4 border-blue-500 border-r-transparent animate-spin"></div>
        </div>
    ) : (
        routing
    );
}
