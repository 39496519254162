import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import axios from "../../../axios";
import { Loader } from "../../../components";
import { SingleVisaTableRow } from "../../../features/VisaMarkup";

export default function MarkupsVisaList() {
    const [visas, setVisas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const { id } = useParams();

    const datafetch = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(
                `/admin/visa/markups/organizations/${id}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setVisas(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        datafetch();
    }, []);

    return (
        <div className="">
            <div className="flex justify-between mb-5">
                <h1 className="text-lg font-[600]">Visa</h1>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <table className="w-full text-sm text-left text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="py-3 px-3">
                                Name
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Price
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Markup
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {visas?.map((visa) => (
                            <SingleVisaTableRow key={visa?._id} visa={visa} />
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}
