import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function LoginPrivateRoute({ children, ...rest }) {
    const { isLoggedIn, user } = useSelector((state) => state.user);

    if (isLoggedIn && user?.role === "agent") {
        return <Navigate replace to="/" />;
    } else if (isLoggedIn && user?.role === "admin") {
        return <Navigate replace to="/admin" />;
    }

    return children;
}

export default LoginPrivateRoute;