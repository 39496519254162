import React, { useState } from "react";
import VisaMarkupModal from "./VisaMarkupModal";

export default function SingleVisaTableRow({ visa }) {
    const [markup, setMarkup] = useState(visa?.markup);
    const [isMarkupModalOpen, setIsMarkupModalOpen] = useState(false);

    return (
        <tr className="bg-white border-b ">
            <td className="py-3 px-3">{visa?.name} </td>
            <td className="py-3 px-3">{visa?.price} AED</td>
            <td className="py-3 px-3">
                <div className="flex items-center gap-[10px]">
                    <span>
                        {markup?.markup
                            ? `${markup?.markup}${
                                  markup?.markupType === "flat" ? " AED" : " %"
                              }`
                            : "N/A"}
                    </span>
                    <span
                        className="flex items-center gap-[15px] text-blue-500 underline cursor-pointer"
                        onClick={() => setIsMarkupModalOpen(true)}
                    >
                        Edit
                    </span>
                </div>
                {isMarkupModalOpen && (
                    <VisaMarkupModal
                        markup={markup}
                        setMarkup={setMarkup}
                        setIsMarkupModalOpen={setIsMarkupModalOpen}
                        visa={visa?._id}
                    />
                )}
            </td>
        </tr>
    );
}
