import React from "react";

const Toggle = ({ defaultChecked, handleToggleChange }) => {
    return (
        <label className="toggle">
            <input
                className="toggle-checkbox"
                type="checkbox"
                defaultChecked={defaultChecked}
                onChange={handleToggleChange}
            />
            <div className="toggle-switch"></div>
        </label>
    );
};

export default Toggle;
