import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader, Loader } from "../../../components";

function TransferCreatePage() {
    const [airports, setAirports] = useState([]);
    const [places, setPlaces] = useState([]);
    const [data, setData] = useState({
        transferFrom: "",
        transferTo: "",
        price1: "",
        price2: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const onDataChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            setError("");

            await axios.post("/admin/transfers/new", data, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            navigate("/admin/transfers");
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            setIsFetching(true);
            const response = await axios.get("/admin/transfers/places-airports", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setPlaces(response.data?.places);
            setIsFetching(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Create Transfers</h2>
            <form onSubmit={submitHandler}>
                <div className="Name mb-5">
                    <label htmlFor="input-name">Transfer From</label>
                    <select
                        className="w-full"
                        id="input-name"
                        name="transferFrom"
                        value={data.transferFrom || ""}
                        onChange={onDataChange}
                        required
                    >
                        <option value="" hidden>
                            Select Location{" "}
                        </option>
                        {places?.map((place, index) => (
                            <option key={index} value={place?._id}>
                                {place?.name}{" "}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="email mb-5">
                    <label htmlFor="input-email">Transfer To</label>
                    <select
                        className="w-full"
                        id="input-email"
                        name="transferTo"
                        value={data.transferTo || ""}
                        onChange={onDataChange}
                        required
                    >
                        <option value="" hidden>
                            Select Location{" "}
                        </option>
                        {places?.map((place) => (
                            <option key={place?.id} value={place?._id}>
                                {place?.name}{" "}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="password mb-5">
                    <label htmlFor="input-password">7 seater price</label>
                    <input
                        type="number"
                        placeholder="Enter Price"
                        id="input-password"
                        name="price1"
                        value={data.price1}
                        onChange={onDataChange}
                        required
                    />
                </div>

                <div className="password mb-5">
                    <label htmlFor="input-password">14 seater price</label>
                    <input
                        type="number"
                        placeholder="Enter Price"
                        id="input-password"
                        name="price2"
                        value={data.price2 || ""}
                        onChange={onDataChange}
                        required
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-full" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Add Transfer"}
                </button>
            </form>
        </div>
    );
}

export default TransferCreatePage;
