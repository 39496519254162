import React from "react";
import { FiLogOut } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { adminSidebarLinks } from "../data";
import { logoutUser } from "../redux/slices/userSlices";

function AdminSidebar() {
    const location = useLocation();
    const dispatch = useDispatch();

    return (
        <div className="bg-[#002366] fixed top-0 left-0 h-[100vh] w-[100%] max-w-[15em] flex flex-col">
            <div className="px-6 py-6 flex items-center gap-[10px]">
                <h1 className="font-bold text-xl text-white">Quotation</h1>
            </div>
            <div className="flex-1 overflow-y-auto">
                <ul>
                    {adminSidebarLinks?.map((link, index) => {
                        return (
                            <li key={index}>
                                <Link
                                    to={link.to}
                                    className={
                                        "relative flex items-center gap-[15px] px-6 py-[14px] text-[15px] transition-all  cursor-pointer " +
                                        (location.pathname === link.to
                                            ? "text-[#fff]"
                                            : "text-[#ccc] hover:text-white")
                                    }
                                >
                                    {link.icon}
                                    {link.name}

                                    {location.pathname === link.to && (
                                        <span className="absolute top-0 left-0 h-full bg-primaryColor w-[4px] rounded-r"></span>
                                    )}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="px-6 py-5 mt-3">
                <button
                    className="flex items-center justify-center gap-[10px] w-[100%] bg-[#fff] text-[#333]"
                    onClick={() => dispatch(logoutUser())}
                >
                    <FiLogOut /> Log Out
                </button>
            </div>
        </div>
    );
}

export default AdminSidebar;
