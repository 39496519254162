import { AgentLayout } from "../layouts";
import AdminLayout from "../layouts/AdminLayout";
import LoginScreen from "../pages/Auth/LoginScreen";
import AdminDashboard from "../pages/superAdmin/Dashboard/AdminDashboard";
import CreatePlace from "../pages/superAdmin/Places/CreatePlace";
import PlaceLists from "../pages/superAdmin/Places/PlaceLists";
import CreateExcursions from "../pages/superAdmin/excursions/CreateExcursions";
import ExcursionLists from "../pages/superAdmin/excursions/ExcursionLists";
import AgentPrivateRoute from "./AgentPrivateRoute";
import AdminPrivateRoute from "./AdminPrivateRoute";
import AirportsListPage from "../pages/superAdmin/Airports/AirportsListPage";
import CreateAirportPage from "../pages/superAdmin/Airports/CreateAirportPage";
import AgentsListPage from "../pages/superAdmin/Agents/AgentsListPage";
import CreateAgentPage from "../pages/superAdmin/Agents/CreateAgentPage";
import AddRoomOccupancyPage from "../pages/superAdmin/RoomOccupancies/AddRoomOccupancyPage";
import RoomOccupancyListPage from "../pages/superAdmin/RoomOccupancies/RoomOccupancyListPage";
import VisaListPage from "../pages/superAdmin/Visa/VisaListPage";
import CreateVisaPage from "../pages/superAdmin/Visa/CreateVisaPage";
import QuotationCreatePage from "../pages/agents/QuotationCreatePage";
import SingleQuotationEmailPage from "../pages/agents/SingleQuotationEmailPage";
import QuotationsListPage from "../pages/agents/QuotationsListPage";
import SingleQuotationPage from "../pages/agents/SingleQuotationPage";
import EditQuotationPage from "../pages/agents/EditQuotationPage";
import ExcursionEditPage from "../pages/superAdmin/excursions/ExcursionEditPage";
import PlaceEditPage from "../pages/superAdmin/Places/PlaceEditPage";
import AirportEditPage from "../pages/superAdmin/Airports/AirportEditPage";
import TransferEditPage from "../pages/superAdmin/Transfers/TransferEditPage";
import TransferCreatePage from "../pages/superAdmin/Transfers/TransferCreatePage";
import TransferListPage from "../pages/superAdmin/Transfers/TransfersListPage";
import RoomOccupancyEditPage from "../pages/superAdmin/RoomOccupancies/RoomOccupancyEditPage";
import VisaEditPage from "../pages/superAdmin/Visa/VisaEditPage";
import HotelsListPage from "../pages/superAdmin/Hotels/HotelsListPage";
import HotelCreatePage from "../pages/superAdmin/Hotels/HotelCreatePage";
import HotelEditPage from "../pages/superAdmin/Hotels/HotelEditPage";
import AdminQuotationsPage from "../pages/superAdmin/AdminQuotationsPage";
import AdmSingleQuotationDetailsPage from "../pages/superAdmin/AdmSingleQuotationDetailsPage";
import AgentQuotationsPage from "../pages/superAdmin/Agents/AgentQuotationsPage";
import SingleAmendmentPage from "../pages/superAdmin/SingleAmendmentPage";
import LoginPrivateRoute from "./LoginPrivateRouter";
import RoomTypeEditPage from "../pages/superAdmin/RoomTypes/RoomTypeEditPage";
import RoomTypeAddPage from "../pages/superAdmin/RoomTypes/RoomTypeAddPage";
import RoomTypesListPage from "../pages/superAdmin/RoomTypes/RoomTypesListPage";
import AgentEditPage from "../pages/superAdmin/Agents/AgentEditPage";
import EditOrganizationPage from "../pages/superAdmin/Organizations/EditOrganizationPage";
import AddOrganizationPage from "../pages/superAdmin/Organizations/AddOrganizationPage";
import OrganizationsListPage from "../pages/superAdmin/Organizations/OrganizationsListPage";
import OrganizationsAgentsListPage from "../pages/superAdmin/Organizations/OrganizationsAgentsListPage";
import MarkupsVisaList from "../pages/superAdmin/Organizations/MarkupsVisaList";
import MarkupsTransferList from "../pages/superAdmin/Organizations/MarkupsTransferList";
import MarkupsExcursionsList from "../pages/superAdmin/Organizations/MarkupsExcursionsList";

const ThemeRoutes = [
    {
        path: "/",
        element: (
            <AgentPrivateRoute>
                <AgentLayout />
            </AgentPrivateRoute>
        ),
        children: [
            { path: "", element: <QuotationsListPage /> },
            { path: "/quotations/create", element: <QuotationCreatePage /> },
            {
                path: "/quotations/email/:quotationNumber",
                element: <SingleQuotationEmailPage />,
            },
            {
                path: "/quotations/:quotationNumber",
                element: <SingleQuotationPage />,
            },
            {
                path: "/quotations/edit/:quotationNumber",
                element: <EditQuotationPage />,
            },
        ],
    },
    {
        path: "/admin",
        element: (
            <AdminPrivateRoute>
                <AdminLayout />
            </AdminPrivateRoute>
        ),
        children: [
            { path: "", element: <AdminDashboard /> },
            { path: "places", element: <PlaceLists /> },
            { path: "places/create", element: <CreatePlace /> },
            { path: "places/edit/:id", element: <PlaceEditPage /> },
            { path: "transfers", element: <TransferListPage /> },
            { path: "transfers/create", element: <TransferCreatePage /> },
            { path: "transfers/edit/:id", element: <TransferEditPage /> },
            { path: "excursions", element: <ExcursionLists /> },
            { path: "excursions/create", element: <CreateExcursions /> },
            { path: "excursions/edit/:id", element: <ExcursionEditPage /> },
            { path: "airports", element: <AirportsListPage /> },
            { path: "airports/create", element: <CreateAirportPage /> },
            { path: "airports/edit/:id", element: <AirportEditPage /> },
            // { path: "agents", element: <AgentsListPage /> },
            { path: "room-occupancies", element: <RoomOccupancyListPage /> },
            { path: "room-occupancies/add", element: <AddRoomOccupancyPage /> },
            {
                path: "room-occupancies/edit/:id",
                element: <RoomOccupancyEditPage />,
            },
            { path: "visa", element: <VisaListPage /> },
            { path: "visa/add", element: <CreateVisaPage /> },
            { path: "visa/edit/:id", element: <VisaEditPage /> },
            { path: "hotels", element: <HotelsListPage /> },
            { path: "hotels/create", element: <HotelCreatePage /> },
            { path: "hotels/edit/:id", element: <HotelEditPage /> },
            { path: "quotations", element: <AdminQuotationsPage /> },
            {
                path: "quotations/:quotationNumber",
                element: <AdmSingleQuotationDetailsPage />,
            },
            { path: "amendments/:id", element: <SingleAmendmentPage /> },
            { path: "room-types", element: <RoomTypesListPage /> },
            { path: "room-types/add", element: <RoomTypeAddPage /> },
            { path: "room-types/edit/:id", element: <RoomTypeEditPage /> },
            { path: "organizations", element: <OrganizationsListPage /> },
            { path: "organizations/create", element: <AddOrganizationPage /> },
            {
                path: "organizations/edit/:id",
                element: <EditOrganizationPage />,
            },
            {
                path: "organizations/:id/agents",
                element: <OrganizationsAgentsListPage />,
            },
            { path: "organizations/:id/agents/:id/quotations", element: <AgentQuotationsPage /> },
            { path: "organizations/:id/agents/create", element: <CreateAgentPage /> },
            { path: "organizations/:id/agents/edit/:id", element: <AgentEditPage /> },
            {
                path: "organizations/:id/excursions/markup",
                element: <MarkupsExcursionsList />,
            },
            {
                path: "organizations/:id/transfers/markup",
                element: <MarkupsTransferList />,
            },
            {
                path: "organizations/:id/visas/markup",
                element: <MarkupsVisaList />,
            },
        ],
    },
    {
        path: "/login",
        element: (
            <LoginPrivateRoute>
                <LoginScreen />
            </LoginPrivateRoute>
        ),
    },
    // {
    //     path: "/register",
    //     element: <RegisterScreen />,
    // },
];

export default ThemeRoutes;
