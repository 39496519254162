import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader } from "../../../components";

export default function AddRoomOccupancyPage() {
    const [data, setData] = useState({
        roomOccupancyName: "",
        roomOccupancyShortName: "",
        noOfPersons: "",
        isForChild: false,
        order: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { jwtToken } = useSelector((state) => state.user);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            await axios.post(
                "/admin/room-occupancies/new",
                { ...data },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            navigate("/admin/room-occupancies");
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Add Room Occupancy</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="countryField">Room Occupancy Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter room occupancy name"
                        id="input-place"
                        name="roomOccupancyName"
                        value={data.roomOccupancyName || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="countryField">Room Occupancy Short Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter room occupancy short Name"
                        id="input-place"
                        name="roomOccupancyShortName"
                        value={data.roomOccupancyShortName || ""}
                        onChange={handleChange}
                        required
                    />
                </div>                

                <div className="mb-4">
                    <label htmlFor="countryField">No of persons</label>
                    <input
                        type="number"
                        className=""
                        placeholder="Enter No of persons"
                        id="input-place"
                        name="noOfPersons"
                        value={data.noOfPersons || ""}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="countryField">Order of listing</label>
                    <input
                        type="number"
                        className=""
                        placeholder="Enter Order"
                        name="order"
                        value={data.order || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-4 flex items-center gap-[10px]">
                    <input
                        type="checkbox"
                        checked={data.isForChild || false}
                        onChange={(e) =>
                            setData((prev) => {
                                return {
                                    ...prev,
                                    isForChild: e.target.checked,
                                };
                            })
                        }
                        className="w-[16px] h-[16px] min-w-[16px] min-h-[16px]"
                    />
                    <label htmlFor="" className="mb-0">
                        Is this room type for children?
                    </label>
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Submit"}
                </button>
            </form>
        </div>
    );
}
