import React, { useState } from "react";
import { FiLogOut, FiMenu } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import { avatarImg } from "../assets/images";
import { logoutUser } from "../redux/slices/userSlices";

function Navbar() {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    return (
        <div className="w-[100%] flex items-center justify-between py-[16px] px-[20px] bg-primaryColor shadow-lg">
            <i className="text-darkColor text-[24px] cursor-pointer flex items-center justify-center invisible">
                <FiMenu />
            </i>
            <div className="relative">
                <div
                    className="flex items-center gap-[12px] cursor-pointer"
                    onClick={() => {
                        setIsDropDownOpen(!isDropDownOpen);
                    }}
                >
                    <div className="relative w-[35px] h-[35px] bg-[#dbdbdb] rounded-full">
                        <img
                            src={avatarImg}
                            alt=""
                            className="w-full h-full object-cover rounded-full"
                        />
                    </div>
                    <div className="text-left">
                        <span className="font-medium text-sm">
                            {user?.name}
                        </span>
                        <span className="block text-[12px] text-gray-500">
                            {user?.email}
                        </span>
                    </div>
                </div>

                {isDropDownOpen && (
                    <div className="absolute bg-[#fff] sahdow-lg hover:bg-[#f6f6f6] right-0 top-[58px] rounded w-[200px] z-10 shadow-sm">
                        <ul>
                            <li>
                                <span
                                    onClick={() => {
                                        dispatch(logoutUser());
                                        setIsDropDownOpen(!isDropDownOpen);
                                    }}
                                    className="flex items-center gap-[12px] text-sm px-4 py-[12px] transition-all"
                                >
                                    <FiLogOut />
                                    <span>Logout</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Navbar;
