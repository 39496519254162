import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "../../../axios";
import { BtnLoader } from "../../../components";

function CreatePlace() {
    const [data, setData] = useState({
        name: "",
        description: "",
        countryId: "",
        stateId: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { jwtToken, countries, states } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            await axios.post(
                "/admin/places/create",
                {
                    ...data,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            navigate("/admin/places");
            setIsLoading(false);
        } catch (error) {
            setError(
                error?.response?.data?.error ||
                    "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setData((prev) => {
            return { ...prev, countryId: countries[0]?._id };
        });
    }, [countries]);

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Create Place</h2>
            <form onSubmit={submitHandler}>
                <div className="mb-4">
                    <label htmlFor="countryField">Country Name</label>
                    <select
                        id="countryField"
                        className="w-full"
                        name="countryId"
                        value={data?.countryId || ""}
                        onChange={handleChange}
                        required
                    >
                        {countries?.map((country, index) => (
                            <option value={country?._id} key={index}>
                                {country?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="countryField">Select Emirate</label>
                    <select
                        id="countryField"
                        className="w-full"
                        name="stateId"
                        value={data?.stateId || ""}
                        onChange={handleChange}
                        required
                    >
                        <option value="" hidden>
                            Select Emirate
                        </option>
                        {states?.map((state, index) => (
                            <option value={state?._id} key={index}>
                                {state?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Place Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter place"
                        id="input-place"
                        name="name"
                        value={data.name || ""}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="description mb-5">
                    <label htmlFor="input-description">Description</label>
                    <textarea
                        type="text"
                        placeholder="Enter description"
                        id="input-description"
                        name="description"
                        value={data.description}
                        onChange={handleChange}
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Add Place"}
                </button>
            </form>
        </div>
    );
}

export default CreatePlace;
