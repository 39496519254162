import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader, Loader } from "../../../components";

export default function HotelEditPage() {
    const [data, setData] = useState({
        name: "",
        place: "",
        description: "",
        starCategory: "",
        defaultRoomType: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [roomTypes, setRoomTypes] = useState([]);

    const navigate = useNavigate();
    const { jwtToken } = useSelector((state) => state.user);
    const { id } = useParams();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            await axios.patch(
                `/admin/hotels/update/${id}`,
                {
                    ...data,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            navigate("/admin/hotels");
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const fetchHotel = useCallback(async () => {
        try {
            setIsFetching(true);

            const { data } = await axios.get(`/admin/hotels/single/${id}`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setData((prev) => {
                return {
                    ...prev,
                    name: data?.name,
                    place: data?.place,
                    description: data?.description,
                    starCategory: data?.starCategory,
                    defaultRoomType: data?.defaultRoomType,
                };
            });
            setIsFetching(false);
        } catch (err) {
            console.log(err);
        }
    }, [id, jwtToken]);

    const fetchRoomTypes = async () => {
        try {
            const { data } = await axios.get("/admin/room-types/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setRoomTypes(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchRoomTypes();
    }, []);

    useEffect(() => {
        fetchHotel();
    }, [fetchHotel]);

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Edit Hotel</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="countryField">Hotel Name</label>
                    <input
                        type="text"
                        name="name"
                        value={data?.name || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Place</label>
                    <input
                        type="text"
                        name="place"
                        value={data.place || ""}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="description mb-5">
                    <label htmlFor="input-description">Description</label>
                    <textarea
                        type="text"
                        placeholder="Enter description"
                        id="input-description"
                        name="description"
                        value={data.description}
                        onChange={handleChange}
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Star Category</label>
                    <select
                        name="starCategory"
                        id=""
                        value={data.starCategory || ""}
                        onChange={handleChange}
                        className="w-full"
                        required
                    >
                        <option value="" hidden>
                            Select Star Category
                        </option>
                        <option value="N/A">N/A</option>
                        <option value="1 star">1 star</option>
                        <option value="2 star">2 star</option>
                        <option value="3 star">3 star</option>
                        <option value="4 star">4 star</option>
                        <option value="5 star">5 star</option>
                        <option value="6 star">6 star</option>
                        <option value="7 star">7 star</option>
                    </select>
                </div>
                <div className="description mb-5">
                    <label htmlFor="input-description">Default Room Type</label>
                    <select
                        name="defaultRoomType"
                        id=""
                        className="w-full"
                        value={data.defaultRoomType || ""}
                        onChange={handleChange}
                    >
                        <option value="" hidden>Select Room Type</option>
                        {roomTypes?.map((roomType, index) => {
                            return (
                                <option value={roomType?._id} key={index}>
                                    {roomType?.roomTypeName}
                                </option>
                            );
                        })}
                    </select>
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Update Hotel"}
                </button>
            </form>
        </div>
    );
}
