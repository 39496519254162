import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import axios from "../../axios";
import {
    ExcSupplementQuotationForm,
    ExcursionQuotationForm,
    HotelQuotationForm,
    QuotationPax,
    QuotationSubmissionForm,
    TransferQuotationForm,
    VisaQuotaionForm,
} from "../../features/QutaionCreatePage";
import {
    clearAllQtnData,
    setQuotationData,
} from "../../redux/slices/quotationsSlice";
import { Loader } from "../../components";

export default function EditQuotationPage() {
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const { initialDataFetching } = useSelector((state) => state.quotations);
    const { quotationNumber } = useParams();
    const dispatch = useDispatch();

    const fetchQuotaionDetails = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(
                `/quotations/single/${quotationNumber}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            dispatch(setQuotationData(response.data));
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [dispatch, jwtToken, quotationNumber]);

    useEffect(() => {
        fetchQuotaionDetails();
    }, [fetchQuotaionDetails]);

    useEffect(() => {
        return () => dispatch(clearAllQtnData());
    }, []);

    return (
        <div className="w-[100%] px-[20px] max-w-[750px] mx-auto">
            {isLoading || initialDataFetching ? (
                <Loader />
            ) : (
                <>
                    <QuotationPax />
                    <span className="block py-10">
                        <hr />
                    </span>
                    <TransferQuotationForm />
                    <span className="block py-10">
                        <hr />
                    </span>
                    <HotelQuotationForm />
                    <span className="block py-10">
                        <hr />
                    </span>
                    <ExcursionQuotationForm />
                    <span className="block py-10">
                        <hr />
                    </span>
                    <ExcSupplementQuotationForm />
                    <span className="block py-10">
                        <hr />
                    </span>
                    <VisaQuotaionForm />
                    <span className="block py-10">
                        <hr />
                    </span>
                    <QuotationSubmissionForm isEdit={true} />
                </>
            )}
        </div>
    );
}
