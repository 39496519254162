import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";

import axios from "../../../axios";
import { Loader } from "../../../components";
import { SingleExcursionTableRow } from "../../../features/ExcursionMarkup";

function MarkupsExcursionsList() {
    const [excursions, setExcursions] = useState([]);
    const [filteredExcursions, setFilteredExcursions] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const { id } = useParams();

    const fetchExcursions = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(
                `/admin/excursions/markups/organizations/${id}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setExcursions(response.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchExcursions();
    }, [fetchExcursions]);

    useEffect(() => {
        if (excursions?.length > 0 && searchText) {
            const searchedExcursions = excursions.filter((excursion) => {
                return excursion?.excursionName
                    ?.toLowerCase()
                    ?.includes(searchText);
            });
            setFilteredExcursions(searchedExcursions);
        } else {
            setFilteredExcursions(excursions);
        }
    }, [searchText, excursions]);

    return (
        <div className="">
            <div className="flex items-center justify-between mb-5">
                <h1 className="text-lg font-[600]">Excursions</h1>
                <div className="flex items-center gap-[20px]">
                    <div>
                        <input
                            type="text"
                            placeholder="Search here..."
                            value={searchText || ""}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <table className="w-full text-sm text-left text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="py-3 px-3">
                                Excursion Name
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Description
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Type
                            </th>
                            <th className="py-3 px-3">Markup</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredExcursions?.map((excursion) => (
                            <SingleExcursionTableRow
                                excursion={excursion}
                                key={excursion?._id}
                            />
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default MarkupsExcursionsList;
