import React, { useEffect } from "react";

import {
    ExcSupplementQuotationForm,
    ExcursionQuotationForm,
    HotelQuotationForm,
    QuotationPax,
    QuotationSubmissionForm,
    TransferQuotationForm,
    VisaQuotaionForm,
} from "../../features/QutaionCreatePage";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components";
import { clearAllQtnData } from "../../redux/slices/quotationsSlice";

export default function QuotationCreatePage() {
    const { initialDataFetching } = useSelector((state) => state.quotations);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(clearAllQtnData());
    }, []);

    if (initialDataFetching) {
        return <Loader />;
    }

    return (
        <div className="w-[100%] px-[20px] max-w-[750px] mx-auto">
            <QuotationPax />
            <span className="block py-10">
                <hr />
            </span>
            <TransferQuotationForm />
            <span className="block py-10">
                <hr />
            </span>
            <HotelQuotationForm />
            <span className="block py-10">
                <hr />
            </span>
            <ExcursionQuotationForm />
            <span className="block py-10">
                <hr />
            </span>
            <ExcSupplementQuotationForm />
            <span className="block py-10">
                <hr />
            </span>
            <VisaQuotaionForm />
            <span className="block py-10">
                <hr />
            </span>
            <QuotationSubmissionForm />
        </div>
    );
}
