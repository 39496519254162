import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    addExcSupplement,
    changeExcSupplementPerPersonPrice,
    changeExcSupplementTransferData,
    changeExcSupplementType,
    removeSelectedExcSupplement,
} from "../../../redux/slices/quotationsSlice";

export default function SingleExcSupplement({ excursion }) {
    const [globalExcursion, setGlobalExcursion] = useState({});

    const { selectedExcSupplementIds, excursions, noOfAdults, noOfChildren } =
        useSelector((state) => state.quotations);

    const dispatch = useDispatch();

    useEffect(() => {
        const objIndex = excursions.findIndex(
            (obj) => obj?._id === excursion?._id
        );
        setGlobalExcursion(excursions[objIndex]);
    }, [excursions, excursion?._id]);

    useEffect(() => {
        if (excursion.value && Object.keys(globalExcursion)?.length > 0) {
            let totalPax =
                (!isNaN(noOfAdults) ? Number(noOfAdults) : 0) +
                (!isNaN(noOfChildren) ? Number(noOfChildren) : 0);

            let calculatedAdultPrice = 0;
            let calculatedChildPrice = 0;

            if (excursion?.excursionType === "transfer") {
                if (excursion?.value === "private") {
                    let totalCapacity =
                        (excursion?.is7SeaterTransfer === true
                            ? 6 * excursion?.sevenSeaterCount
                            : 0) +
                        (excursion?.is14SeaterTransfer === true
                            ? 12 * excursion?.fourteenSeaterCount
                            : 0);
                    let totalPvtTransferPrice =
                        (excursion?.is7SeaterTransfer === true
                            ? globalExcursion?.transferPricing
                                  ?.sevenSeaterPrice *
                              excursion?.sevenSeaterCount
                            : 0) +
                        (excursion?.is14SeaterTransfer === true
                            ? globalExcursion?.transferPricing
                                  ?.fourteenSeaterPrice *
                              excursion?.fourteenSeaterCount
                            : 0);

                    let divVal = 1;
                    if (totalPax > totalCapacity) {
                        divVal = totalCapacity;
                    } else if (totalPax > 1 && totalPax <= totalCapacity) {
                        divVal = totalPax;
                    }

                    calculatedAdultPrice = totalPvtTransferPrice / divVal;
                    calculatedChildPrice = totalPvtTransferPrice / divVal;
                } else if (excursion?.value === "shared") {
                    calculatedAdultPrice =
                        globalExcursion?.transferPricing?.sicPrice;
                    calculatedChildPrice =
                        globalExcursion?.transferPricing?.sicPrice;
                }
            } else if (excursion?.excursionType === "ticket") {
                if (excursion?.value === "ticket") {
                    calculatedAdultPrice =
                        globalExcursion?.ticketPricing?.adultPrice;
                    calculatedChildPrice =
                        globalExcursion?.ticketPricing?.childPrice;
                } else if (excursion?.value === "shared") {
                    calculatedAdultPrice =
                        globalExcursion?.ticketPricing?.sicWithTicketAdultPrice;
                    calculatedChildPrice =
                        globalExcursion?.ticketPricing?.sicWithTicketChildPrice;
                } else if (excursion?.value === "private") {
                    let totalCapacity =
                        (excursion?.is7SeaterTransfer === true
                            ? 6 * excursion?.sevenSeaterCount
                            : 0) +
                        (excursion?.is14SeaterTransfer === true
                            ? 12 * excursion?.fourteenSeaterCount
                            : 0);
                    let totalPvtTransferAdultPrice =
                        (excursion?.is7SeaterTransfer === true
                            ? globalExcursion?.ticketPricing
                                  ?.privateTransferSevenSeaterPriceAdult *
                              excursion?.sevenSeaterCount
                            : 0) +
                        (excursion?.is14SeaterTransfer === true
                            ? globalExcursion?.ticketPricing
                                  ?.privateTransferFourteenSeaterPriceAdult *
                              excursion?.fourteenSeaterCount
                            : 0);
                    let totalPvtTransferChildPrice =
                        (excursion?.is7SeaterTransfer === true
                            ? globalExcursion?.ticketPricing
                                  ?.privateTransferSevenSeaterPriceChild *
                              excursion?.sevenSeaterCount
                            : 0) +
                        (excursion?.is14SeaterTransfer === true
                            ? globalExcursion?.ticketPricing
                                  ?.privateTransferFourteenSeaterPriceChild *
                              excursion?.fourteenSeaterCount
                            : 0);

                    let divVal = 1;
                    if (totalPax > totalCapacity) {
                        divVal = totalCapacity;
                    } else if (totalPax > 1 && totalPax <= totalCapacity) {
                        divVal = totalPax;
                    }

                    calculatedAdultPrice =
                        totalPvtTransferAdultPrice / divVal +
                        (globalExcursion?.ticketPricing?.adultPrice
                            ? globalExcursion?.ticketPricing?.adultPrice
                            : 0);
                    calculatedChildPrice =
                        totalPvtTransferChildPrice / divVal +
                        (globalExcursion?.ticketPricing?.childPrice
                            ? globalExcursion?.ticketPricing?.childPrice
                            : 0);
                }
            }

            dispatch(
                changeExcSupplementPerPersonPrice({
                    _id: excursion?._id,
                    perPersonAdultPrice: calculatedAdultPrice,
                    perPersonChildPrice: calculatedChildPrice,
                })
            );
        }
    }, [
        excursion.value,
        noOfAdults,
        noOfChildren,
        globalExcursion,
        excursion?.is7SeaterTransfer,
        excursion?.sevenSeaterCount,
        excursion?.is14SeaterTransfer,
        excursion?.fourteenSeaterCount,
    ]);

    return (
        <div className="mb-6 bg-[#f6f6f6] p-4">
            <div className="flex items-start gap-[10px] ">
                <input
                    type="checkbox"
                    className="w-[16px] h-[16px] min-w-[16px] min-h-[16px] mt-[4px]"
                    onChange={() => {
                        if (selectedExcSupplementIds?.includes(excursion?._id)) {
                            dispatch(removeSelectedExcSupplement(excursion?._id));
                        } else {
                            dispatch(addExcSupplement(excursion));
                        }
                    }}
                    checked={selectedExcSupplementIds?.includes(excursion?._id)}
                />

                <label htmlFor="" className="mb-0 font-[500]">
                    {excursion?.excursionName} -{" "}
                    <span className="capitalize text-orange-500 leading-[24px]">
                        {excursion?.excursionType === "ticket"
                            ? "Ticket With Transfer"
                            : excursion?.excursionType}
                    </span>
                    {excursion?.excursionType === "regular" &&
                        " - " + excursion?.prices?.price1 + " AED"}
                </label>
            </div>

            {excursion?.excursionType !== "regular" && (
                <div className="flex items-center gap-4 flex-wrap mt-4">
                    {excursion?.excursionType === "transfer" && (
                        <select
                            className="w-full"
                            value={excursion?.value || ""}
                            onChange={(e) => {
                                dispatch(
                                    changeExcSupplementType({
                                        value: e.target.value,
                                        _id: excursion?._id,
                                    })
                                );
                            }}
                        >
                            <option value="" hidden>
                                Select Type
                            </option>
                            {globalExcursion?.transferPricing?.sicPrice && (
                                <option value="shared">Shared</option>
                            )}
                            {(globalExcursion?.transferPricing?.sevenSeaterPrice ||
                                globalExcursion?.transferPricing
                                    ?.fourteenSeaterPrice) && (
                                <option value="private">Private</option>
                            )}
                        </select>
                    )}

                    {excursion?.value === "private" && (
                        <div className="grid grid-cols-2 gap-[1.5em] w-full">
                            {globalExcursion?.transferPricing
                                ?.sevenSeaterPrice && (
                                <div>
                                    <div className="flex items-center gap-[10px]">
                                        <input
                                            type="checkbox"
                                            className="w-[16px] h-[16px]"
                                            name="is7SeaterTransfer"
                                            defaultChecked={
                                                excursion?.is7SeaterTransfer ||
                                                false
                                            }
                                            onChange={(e) => {
                                                dispatch(
                                                    changeExcSupplementTransferData(
                                                        {
                                                            name: "is7SeaterTransfer",
                                                            value: e.target
                                                                .checked,
                                                            _id: excursion?._id,
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                        <label htmlFor="" className="mb-0">
                                            7 seater
                                        </label>
                                    </div>
                                    {excursion?.is7SeaterTransfer === true && (
                                        <input
                                            type="number"
                                            className="w-full mt-3"
                                            name="sevenSeaterCount"
                                            value={
                                                excursion?.sevenSeaterCount ||
                                                ""
                                            }
                                            onChange={(e) => {
                                                dispatch(
                                                    changeExcSupplementTransferData(
                                                        {
                                                            name: "sevenSeaterCount",
                                                            value: Number(
                                                                e.target.value
                                                            ),
                                                            _id: excursion?._id,
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            )}

                            {globalExcursion?.transferPricing
                                ?.fourteenSeaterPrice && (
                                <div>
                                    <div className="flex items-center gap-[10px]">
                                        <input
                                            type="checkbox"
                                            className="w-[16px] h-[16px]"
                                            name="is14SeaterTransfer"
                                            defaultChecked={
                                                excursion?.is14SeaterTransfer ||
                                                false
                                            }
                                            onChange={(e) => {
                                                dispatch(
                                                    changeExcSupplementTransferData(
                                                        {
                                                            name: "is14SeaterTransfer",
                                                            value: e.target
                                                                .checked,
                                                            _id: excursion?._id,
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                        <label htmlFor="" className="mb-0">
                                            12 seater
                                        </label>
                                    </div>
                                    {excursion?.is14SeaterTransfer === true && (
                                        <input
                                            type="number"
                                            className="w-full mt-3"
                                            name="fourteenSeaterCount"
                                            value={
                                                excursion?.fourteenSeaterCount ||
                                                ""
                                            }
                                            onChange={(e) => {
                                                dispatch(
                                                    changeExcSupplementTransferData(
                                                        {
                                                            name: "fourteenSeaterCount",
                                                            value: Number(
                                                                e.target.value
                                                            ),
                                                            _id: excursion?._id,
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {/* TICKET TYPE START HERE  */}
                    {excursion?.excursionType === "ticket" && (
                        <div className="flex items-center gap-2 w-full">
                            <select
                                className="w-full"
                                name=""
                                onChange={(e) => {
                                    dispatch(
                                        changeExcSupplementType({
                                            _id: excursion?._id,
                                            value: e.target.value,
                                        })
                                    );
                                }}
                                value={excursion?.value || ""}
                            >
                                <option value="" hidden>
                                    Select Type
                                </option>
                                {globalExcursion?.ticketPricing?.adultPrice &&
                                    globalExcursion?.ticketPricing
                                        ?.childPrice && (
                                        <option value="ticket">
                                            Ticket Only
                                        </option>
                                    )}
                                {globalExcursion?.ticketPricing
                                    ?.sicWithTicketAdultPrice &&
                                    globalExcursion?.ticketPricing
                                        ?.sicWithTicketChildPrice && (
                                        <option value="shared">Shared</option>
                                    )}
                                {((globalExcursion?.ticketPricing
                                    ?.privateTransferSevenSeaterPriceChild &&
                                    globalExcursion?.ticketPricing
                                        ?.privateTransferSevenSeaterPriceAdult) ||
                                    (globalExcursion?.ticketPricing
                                        ?.privateTransferFourteenSeaterPriceChild &&
                                        globalExcursion?.ticketPricing
                                            ?.privateTransferFourteenSeaterPriceAdult)) && (
                                    <option value="private">Private</option>
                                )}
                            </select>
                        </div>
                    )}

                    {excursion?.value === "private" &&
                        ((globalExcursion?.ticketPricing
                            ?.privateTransferSevenSeaterPriceChild &&
                            globalExcursion?.ticketPricing
                                ?.privateTransferSevenSeaterPriceAdult) ||
                            (globalExcursion?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild &&
                                globalExcursion?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceAdult)) && (
                            <div className="grid grid-cols-2 gap-[1.5em] w-full">
                                {globalExcursion?.ticketPricing
                                    ?.privateTransferSevenSeaterPriceChild &&
                                    globalExcursion?.ticketPricing
                                        ?.privateTransferSevenSeaterPriceAdult && (
                                        <div>
                                            <div className="flex items-center gap-[10px]">
                                                <input
                                                    type="checkbox"
                                                    className="w-[16px] h-[16px]"
                                                    name="is7SeaterTransfer"
                                                    defaultChecked={
                                                        excursion?.is7SeaterTransfer ||
                                                        false
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            changeExcSupplementTransferData(
                                                                {
                                                                    name: "is7SeaterTransfer",
                                                                    value: e
                                                                        .target
                                                                        .checked,
                                                                    _id: excursion?._id,
                                                                }
                                                            )
                                                        );
                                                    }}
                                                />
                                                <label
                                                    htmlFor=""
                                                    className="mb-0"
                                                >
                                                    7 seater
                                                </label>
                                            </div>
                                            {excursion?.is7SeaterTransfer ===
                                                true && (
                                                <input
                                                    type="number"
                                                    className="w-full mt-3"
                                                    name="sevenSeaterCount"
                                                    value={
                                                        excursion?.sevenSeaterCount ||
                                                        ""
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            changeExcSupplementTransferData(
                                                                {
                                                                    name: "sevenSeaterCount",
                                                                    value: Number(
                                                                        e.target
                                                                            .value
                                                                    ),
                                                                    _id: excursion?._id,
                                                                }
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}

                                {globalExcursion?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceChild &&
                                    globalExcursion?.ticketPricing
                                        ?.privateTransferFourteenSeaterPriceAdult && (
                                        <div>
                                            <div className="flex items-center gap-[10px]">
                                                <input
                                                    type="checkbox"
                                                    className="w-[16px] h-[16px]"
                                                    name="is14SeaterTransfer"
                                                    defaultChecked={
                                                        excursion?.is14SeaterTransfer ||
                                                        false
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            changeExcSupplementTransferData(
                                                                {
                                                                    name: "is14SeaterTransfer",
                                                                    value: e
                                                                        .target
                                                                        .checked,
                                                                    _id: excursion?._id,
                                                                }
                                                            )
                                                        );
                                                    }}
                                                />
                                                <label
                                                    htmlFor=""
                                                    className="mb-0"
                                                >
                                                    12 seater
                                                </label>
                                            </div>
                                            {excursion?.is14SeaterTransfer ===
                                                true && (
                                                <input
                                                    type="number"
                                                    className="w-full mt-3"
                                                    name="fourteenSeaterCount"
                                                    value={
                                                        excursion?.fourteenSeaterCount ||
                                                        ""
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            changeExcSupplementTransferData(
                                                                {
                                                                    name: "fourteenSeaterCount",
                                                                    value: Number(
                                                                        e.target
                                                                            .value
                                                                    ),
                                                                    _id: excursion?._id,
                                                                }
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                            </div>
                        )}
                </div>
            )}

            <div className="mt-5 text-sm grid grid-cols-2">
                <span className="block">
                    Adult Price:{" "}
                    <span className="font-medium">
                        {excursion?.perPersonAdultPrice?.toFixed(2)} AED
                    </span>
                </span>
                <span className="block">
                    Child Price:{" "}
                    <span className="font-medium">
                        {excursion?.perPersonChildPrice?.toFixed(2)} AED
                    </span>
                </span>
            </div>
        </div>
    );
}
