import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";

import axios from "../../../axios";
import { Loader } from "../../../components";

function TransferListPage() {
    const [transfers, setTransfers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);

    const datafetch = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get("/admin/transfers/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setTransfers(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const isConfirm = window.confirm("Are you sure to delete?");
            if (isConfirm) {
                await axios.delete(`/admin/transfers/delete/${id}`, {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                });
                const filteredItems = transfers.filter((item) => {
                    return item?._id !== id;
                });
                setTransfers(filteredItems);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        datafetch();
    }, []);

    return (
        <div className="">
            <div className="flex justify-between mb-5">
                <h1 className="text-lg font-[600]">Transfers</h1>
                <Link to="/admin/transfers/create">
                    <button className="px-4 bg-darkColor py-2 rounded-md">
                        + Create
                    </button>
                </Link>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <table className="w-full text-sm text-left text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="py-3 px-3">
                                From
                            </th>
                            <th scope="col" className="py-3 px-3">
                                To
                            </th>
                            <th scope="col" className="py-3 px-3">
                                7 seater
                            </th>
                            <th scope="col" className="py-3 px-3">
                                14 seater
                            </th>
                            <th className="py-3 px-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transfers &&
                            transfers.map((trans, index) => (
                                <tr className="bg-white border-b " key={index}>
                                    <td className="py-3 px-3">
                                        {trans?.transferFrom?.name}{" "}
                                    </td>
                                    <td className="py-3 px-3">
                                        {trans?.transferTo?.name}{" "}
                                    </td>
                                    <td className="py-3 px-3">
                                        {trans.price1} AED
                                    </td>
                                    <td className="py-3 px-3">
                                        {trans.price2} AED
                                    </td>
                                    <td className="py-3 px-3">
                                        <div className="flex items-center gap-[15px]">
                                            <Link
                                                to={`/admin/transfers/edit/${trans?._id}`}
                                            >
                                                <button className="flex items-center justify-center h-auto p-0 text-green-500 text-xl bg-transparent">
                                                    <BiEditAlt />
                                                </button>
                                            </Link>
                                            <button
                                                onClick={() =>
                                                    handleDelete(trans?._id)
                                                }
                                                className="flex items-center justify-center p-0 h-auto text-red-500 text-xl bg-transparent"
                                            >
                                                <MdDelete />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default TransferListPage;
