import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    places: [],
    excursions: [],
    airports: [],
    roomOccupancies: [],
    visas: [],
    hotels: [],
    roomTypes: [],
    initialDataFetching: true,
    clientName: "Sir / Madam",
    noOfAdults: "",
    noOfChildren: "",
    checkInDate: "",
    checkOutDate: "",
    childrenAges: [],
    transfer: {
        stateId: "",
        transferFrom: "",
        transferTo: "",
        isReturn: true,
        price: 0,
        is7SeaterTransfer: true,
        sevenSeaterCount: 1,
        is14SeaterTransfer: false,
        fourteenSeaterCount: 1,
    },
    hotelQt: {
        totalPrice: 0,
        isTourismFeeIncluded: true,
        hotels: [
            {
                hotelId: "",
                hotelName: "",
                placeName: "",
                starCategory: "",
                isBreakfastIncluded: false,
                isRefundable: false,
                roomTypeName: "",
            },
        ],
    },
    dateError: "",
    selectedExcursions: [],
    excursionTotalPrice: 0,
    selectedExcursionsIds: [],
    excursionTransferType: "all",
    selectedExcSupplements: [],
    excSupplementsTotalPrice: 0,
    selectedExcSupplementIds: [],
    excSupplementTransferType: "all",
    selectedVisa: {},
    otbPrice: "",
    isTransferQuotationDisabled: false,
    isHotelQuotationDisabled: false,
    quotationCurrency: "USD",
    markup: "",
    markupType: "percentage",
    hotelDisabledRemark: "",
};

export const quotationsSlice = createSlice({
    name: "quotations",
    initialState,
    reducers: {
        setInititalData: (state, action) => {
            state.places = action.payload?.places;
            state.excursions = action.payload?.excursions;
            state.airports = action.payload?.airports;
            state.roomOccupancies = action.payload?.roomOccupancies;
            state.visas = action.payload?.visas;
            state.hotels = action.payload?.hotels;
            state.roomTypes = action.payload?.roomTypes;
            if (!state.hotelQt.hotels[0].roomOccupancies) {
                state.hotelQt.hotels[0].roomOccupancies =
                    action.payload?.roomOccupancies;
            }
            state.initialDataFetching = false;
        },
        handleTransferChange: (state, action) => {
            state.transfer[action.payload?.name] = action.payload?.value;
        },
        addHotel: (state, action) => {
            state.hotelQt?.hotels?.push({
                hotelId: "",
                isBreakfastIncluded: false,
                isRefundable: false,
                isTourismFeeIncluded: true,
                roomOccupancies: state.roomOccupancies,
            });
        },
        removeHotel: (state, action) => {
            const filteredHotels = state.hotelQt?.hotels?.filter((_, index) => {
                return index !== action.payload;
            });
            state.hotelQt.hotels = filteredHotels;
        },
        handleHotelQtDataChange: (state, action) => {
            state.hotelQt[action?.payload?.name] = action.payload?.value;
        },
        handleHotelsDataChange: (state, action) => {
            state.hotelQt.hotels[action.payload?.hotelIndex][
                action.payload?.name
            ] = action.payload?.value;
        },
        updateHotelBreakfastIncludedOrNot: (state, action) => {
            state.hotelQt.hotels[
                action.payload.hotelIndex
            ].isBreakfastIncluded = action.payload.isBreakfastIncluded;
        },
        updateHotelIsRefundable: (state, action) => {
            state.hotelQt.hotels[action.payload.hotelIndex].isRefundable =
                action.payload.isRefundable;
        },
        updateIsTourismFeeIncluded: (state, action) => {
            state.hotelQt.isTourismFeeIncluded = action.payload;
        },
        handleRowItemChange: (state, action) => {
            state.hotelQt.hotels[action.payload?.hotelIndex].roomOccupancies[
                action.payload.roomIndex
            ].price = action.payload.value;
        },
        handleRoomOccupancyChange: (state, action) => {
            const roomOccupancyObjIndex = state.roomOccupancies?.findIndex(
                (obj) => obj._id === action.payload?.roomOccupancyId
            );
            if (roomOccupancyObjIndex !== -1) {
                let roomOccupancy =
                    state.roomOccupancies[roomOccupancyObjIndex];
                state.hotelQt.hotels[action.payload?.hotelIndex].roomRows[
                    action.payload.roomIndex
                ].roomOccupancyName = roomOccupancy?.roomOccupancyName;
                state.hotelQt.hotels[action.payload?.hotelIndex].roomRows[
                    action.payload.roomIndex
                ].noOfPersons = roomOccupancy?.noOfPersons;
                state.hotelQt.hotels[action.payload?.hotelIndex].roomRows[
                    action.payload.roomIndex
                ].roomOccupancyId = roomOccupancy?._id;
            }
        },
        handleNoOfAdultsChange: (state, action) => {
            state.noOfAdults = Number(action.payload);
        },
        handleNoOfChildrenChange: (state, action) => {
            state.noOfChildren = Number(action.payload);
            for (let i = 0; i < state.noOfChildren; i++) {
                state.childrenAges?.push("");
            }
            state.childrenAges = state.childrenAges?.slice(
                0,
                state.noOfChildren
            );
        },
        handleChildrenAgeChange: (state, action) => {
            if (!action.payload?.value) {
                state.childrenAges[action.payload?.index] = "";
            } else {
                state.childrenAges[action.payload?.index] =
                    action.payload.value;
            }
        },
        addExcusrsion: (state, action) => {
            if (action.payload) {
                let data = { ...action.payload };
                data.perPersonAdultPrice = 0;
                data.perPersonChildPrice = 0;

                data.is7SeaterTransfer = false;
                data.sevenSeaterCount = 1;
                data.is14SeaterTransfer = false;
                data.fourteenSeaterCount = 1;

                let totalPax =
                    (!isNaN(state.noOfAdults) ? Number(state.noOfAdults) : 0) +
                    (!isNaN(state.noOfChildren)
                        ? Number(state.noOfChildren)
                        : 0);

                if (action?.payload?.excursionType === "transfer") {
                    if (state.excursionTransferType === "private") {
                        if (
                            data?.transferPricing?.sevenSeaterPrice &&
                            !(
                                data.transferPricing?.fourteenSeaterPrice &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (data?.transferPricing?.fourteenSeaterPrice) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        } else if (data?.transferPricing?.sicPrice) {
                            data.value = "shared";
                        } else {
                            data.value = "";
                        }
                    } else if (
                        state.excursionTransferType === "shared" ||
                        state.excursionTransferType === "all"
                    ) {
                        if (data?.transferPricing?.sicPrice) {
                            data.value = "shared";
                        } else if (
                            data.transferPricing?.sevenSeaterPrice &&
                            !(
                                data.transferPricing?.fourteenSeaterPrice &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (data.transferPricing?.fourteenSeaterPrice) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        } else {
                            data.value = "";
                        }
                    }
                } else if (action?.payload?.excursionType === "ticket") {
                    if (state.excursionTransferType === "all") {
                        if (
                            data.ticketPricing?.adultPrice &&
                            data?.ticketPricing?.childPrice
                        ) {
                            data.value = "ticket";
                        } else if (
                            data?.ticketPricing?.sicWithTicketAdultPrice &&
                            data?.ticketPricing?.sicWithTicketChildPrice
                        ) {
                            data.value = "shared";
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceChild &&
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceAdult &&
                            !(
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceAdult &&
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceChild &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceAdult &&
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild
                        ) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        }
                    } else if (state.excursionTransferType === "shared") {
                        if (
                            data?.ticketPricing?.sicWithTicketAdultPrice &&
                            data?.ticketPricing?.sicWithTicketChildPrice
                        ) {
                            data.value = "shared";
                        } else if (
                            data.ticketPricing?.adultPrice &&
                            data?.ticketPricing?.childPrice
                        ) {
                            data.value = "ticket";
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceChild &&
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceAdult &&
                            !(
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceAdult &&
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceChild &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceAdult &&
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild
                        ) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        }
                    } else if (state.excursionTransferType === "private") {
                        if (
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceChild &&
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceAdult &&
                            !(
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceAdult &&
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceChild &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceAdult &&
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild
                        ) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        } else if (
                            data.ticketPricing?.adultPrice &&
                            data?.ticketPricing?.childPrice
                        ) {
                            data.value = "ticket";
                        } else if (
                            data?.ticketPricing?.sicWithTicketAdultPrice &&
                            data?.ticketPricing?.sicWithTicketChildPrice
                        ) {
                            data.value = "shared";
                        }
                    }
                }
                data.excursionId = action?.payload?._id;
                state.selectedExcursions.push(data);
                state.selectedExcursionsIds.push(action.payload?._id);
            }
        },
        changeExcursionType: (state, action) => {
            const selectedExcursionIndex = state.selectedExcursions?.findIndex(
                (obj) => obj._id === action.payload?._id
            );
            const excursionIndex = state.excursions?.findIndex(
                (obj) => obj._id === action.payload?._id
            );
            if (selectedExcursionIndex !== -1 && excursionIndex !== -1) {
                state.selectedExcursions[selectedExcursionIndex].value =
                    action.payload?.value;

                let totalPax =
                    (!isNaN(state.noOfAdults) ? Number(state.noOfAdults) : 0) +
                    (!isNaN(state.noOfChildren)
                        ? Number(state.noOfChildren)
                        : 0);

                if (
                    state.selectedExcursions[selectedExcursionIndex]
                        ?.excursionType === "transfer" &&
                    action.payload?.value === "private"
                ) {
                    if (
                        state.excursions[excursionIndex]?.transferPricing
                            ?.sevenSeaterPrice &&
                        !(
                            state.excursions[excursionIndex]?.transferPricing
                                ?.fourteenSeaterPrice && totalPax > 6
                        )
                    ) {
                        state.selectedExcursions[
                            selectedExcursionIndex
                        ].is7SeaterTransfer = true;
                        state.selectedExcursions[
                            selectedExcursionIndex
                        ].sevenSeaterCount = 1;
                    } else {
                        state.selectedExcursions[
                            selectedExcursionIndex
                        ].is14SeaterTransfer = true;
                        state.selectedExcursions[
                            selectedExcursionIndex
                        ].fourteenSeaterCount = 1;
                    }
                }

                if (
                    state.selectedExcursions[selectedExcursionIndex]
                        ?.excursionType === "ticket" &&
                    action.payload?.value === "private"
                ) {
                    if (
                        state.excursions[excursionIndex]?.ticketPricing
                            ?.privateTransferSevenSeaterPriceChild &&
                        state.excursions[excursionIndex]?.ticketPricing
                            ?.privateTransferSevenSeaterPriceAdult &&
                        !(
                            state.excursions[excursionIndex]?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceAdult &&
                            state.excursions[excursionIndex]?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild &&
                            totalPax > 6
                        )
                    ) {
                        state.selectedExcursions[
                            selectedExcursionIndex
                        ].is7SeaterTransfer = true;
                        state.selectedExcursions[
                            selectedExcursionIndex
                        ].sevenSeaterCount = 1;
                    } else {
                        state.selectedExcursions[
                            selectedExcursionIndex
                        ].is14SeaterTransfer = true;
                        state.selectedExcursions[
                            selectedExcursionIndex
                        ].fourteenSeaterCount = 1;
                    }
                }
            }
        },
        changeExcursionTransferData: (state, action) => {
            const selectedExcursionIndex = state.selectedExcursions?.findIndex(
                (obj) => obj._id === action.payload?._id
            );
            if (selectedExcursionIndex !== -1) {
                state.selectedExcursions[selectedExcursionIndex][
                    action.payload?.name
                ] = action.payload.value;
            }
        },
        changeExcursionPerPersonPrice: (state, action) => {
            for (let i = 0; i < state.selectedExcursions?.length; i++) {
                if (state.selectedExcursions[i]?._id === action.payload?._id) {
                    state.selectedExcursions[i].perPersonAdultPrice =
                        action.payload?.perPersonAdultPrice;
                    state.selectedExcursions[i].perPersonChildPrice =
                        action.payload?.perPersonChildPrice;
                    break;
                }
            }
        },
        removeSelectedExcursion: (state, action) => {
            const filteredExcursions = state.selectedExcursions?.filter(
                (item) => {
                    return item?._id !== action.payload;
                }
            );
            state.selectedExcursions = filteredExcursions;
            const filteredIds = state.selectedExcursionsIds?.filter((id) => {
                return id !== action.payload;
            });
            state.selectedExcursionsIds = filteredIds;
        },
        addExcSupplement: (state, action) => {
            if (action.payload) {
                let data = { ...action.payload };
                data.perPersonAdultPrice = 0;
                data.perPersonChildPrice = 0;

                data.is7SeaterTransfer = false;
                data.sevenSeaterCount = 1;
                data.is14SeaterTransfer = false;
                data.fourteenSeaterCount = 1;

                let totalPax =
                    (!isNaN(state.noOfAdults) ? Number(state.noOfAdults) : 0) +
                    (!isNaN(state.noOfChildren)
                        ? Number(state.noOfChildren)
                        : 0);

                if (action?.payload?.excursionType === "transfer") {
                    if (state.excSupplementTransferType === "private") {
                        if (
                            data?.transferPricing?.sevenSeaterPrice &&
                            !(
                                data.transferPricing?.fourteenSeaterPrice &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (data?.transferPricing?.fourteenSeaterPrice) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        } else if (data?.transferPricing?.sicPrice) {
                            data.value = "shared";
                        } else {
                            data.value = "";
                        }
                    } else if (
                        state.excSupplementTransferType === "shared" ||
                        state.excSupplementTransferType === "all"
                    ) {
                        if (data?.transferPricing?.sicPrice) {
                            data.value = "shared";
                        } else if (
                            data.transferPricing?.sevenSeaterPrice &&
                            !(
                                data.transferPricing?.fourteenSeaterPrice &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (data.transferPricing?.fourteenSeaterPrice) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        } else {
                            data.value = "";
                        }
                    }
                } else if (action?.payload?.excursionType === "ticket") {
                    if (state.excSupplementTransferType === "all") {
                        if (
                            data.ticketPricing?.adultPrice &&
                            data?.ticketPricing?.childPrice
                        ) {
                            data.value = "ticket";
                        } else if (
                            data?.ticketPricing?.sicWithTicketAdultPrice &&
                            data?.ticketPricing?.sicWithTicketChildPrice
                        ) {
                            data.value = "shared";
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceChild &&
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceAdult &&
                            !(
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceAdult &&
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceChild &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceAdult &&
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild
                        ) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        }
                    } else if (state.excSupplementTransferType === "shared") {
                        if (
                            data?.ticketPricing?.sicWithTicketAdultPrice &&
                            data?.ticketPricing?.sicWithTicketChildPrice
                        ) {
                            data.value = "shared";
                        } else if (
                            data.ticketPricing?.adultPrice &&
                            data?.ticketPricing?.childPrice
                        ) {
                            data.value = "ticket";
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceChild &&
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceAdult &&
                            !(
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceAdult &&
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceChild &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceAdult &&
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild
                        ) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        }
                    } else if (state.excSupplementTransferType === "private") {
                        if (
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceChild &&
                            data?.ticketPricing
                                ?.privateTransferSevenSeaterPriceAdult &&
                            !(
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceAdult &&
                                data?.ticketPricing
                                    ?.privateTransferFourteenSeaterPriceChild &&
                                totalPax > 6
                            )
                        ) {
                            data.value = "private";
                            data.is7SeaterTransfer = true;
                            data.sevenSeaterCount = 1;
                        } else if (
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceAdult &&
                            data?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild
                        ) {
                            data.value = "private";
                            data.is14SeaterTransfer = true;
                            data.fourteenSeaterCount = 1;
                        } else if (
                            data.ticketPricing?.adultPrice &&
                            data?.ticketPricing?.childPrice
                        ) {
                            data.value = "ticket";
                        } else if (
                            data?.ticketPricing?.sicWithTicketAdultPrice &&
                            data?.ticketPricing?.sicWithTicketChildPrice
                        ) {
                            data.value = "shared";
                        }
                    }
                }
                data.excursionId = action?.payload?._id;
                state.selectedExcSupplements.push(data);
                state.selectedExcSupplementIds.push(action.payload?._id);
            }
        },
        changeExcSupplementPerPersonPrice: (state, action) => {
            for (let i = 0; i < state.selectedExcSupplements?.length; i++) {
                if (
                    state.selectedExcSupplements[i]?._id === action.payload?._id
                ) {
                    state.selectedExcSupplements[i].perPersonAdultPrice =
                        action.payload?.perPersonAdultPrice;
                    state.selectedExcSupplements[i].perPersonChildPrice =
                        action.payload?.perPersonChildPrice;
                    break;
                }
            }
        },
        changeExcSupplementTransferData: (state, action) => {
            const selectedExcursionIndex =
                state.selectedExcSupplements?.findIndex(
                    (obj) => obj._id === action.payload?._id
                );
            if (selectedExcursionIndex !== -1) {
                state.selectedExcSupplements[selectedExcursionIndex][
                    action.payload?.name
                ] = action.payload.value;
            }
        },
        changeExcSupplementType: (state, action) => {
            const selectedExcursionIndex =
                state.selectedExcSupplements?.findIndex(
                    (obj) => obj._id === action.payload?._id
                );
            const excursionIndex = state.excursions?.findIndex(
                (obj) => obj._id === action.payload?._id
            );
            if (selectedExcursionIndex !== -1 && excursionIndex !== -1) {
                state.selectedExcSupplements[selectedExcursionIndex].value =
                    action.payload?.value;

                let totalPax =
                    (!isNaN(state.noOfAdults) ? Number(state.noOfAdults) : 0) +
                    (!isNaN(state.noOfChildren)
                        ? Number(state.noOfChildren)
                        : 0);

                if (
                    state.selectedExcSupplements[selectedExcursionIndex]
                        ?.excursionType === "transfer" &&
                    action.payload?.value === "private"
                ) {
                    if (
                        state.excursions[excursionIndex]?.transferPricing
                            ?.sevenSeaterPrice &&
                        !(
                            state.excursions[excursionIndex]?.transferPricing
                                ?.fourteenSeaterPrice && totalPax > 6
                        )
                    ) {
                        state.selectedExcSupplements[
                            selectedExcursionIndex
                        ].is7SeaterTransfer = true;
                        state.selectedExcSupplements[
                            selectedExcursionIndex
                        ].sevenSeaterCount = 1;
                    } else {
                        state.selectedExcSupplements[
                            selectedExcursionIndex
                        ].is14SeaterTransfer = true;
                        state.selectedExcSupplements[
                            selectedExcursionIndex
                        ].fourteenSeaterCount = 1;
                    }
                }

                if (
                    state.selectedExcSupplements[selectedExcursionIndex]
                        ?.excursionType === "ticket" &&
                    action.payload?.value === "private"
                ) {
                    if (
                        state.excursions[excursionIndex]?.ticketPricing
                            ?.privateTransferSevenSeaterPriceChild &&
                        state.excursions[excursionIndex]?.ticketPricing
                            ?.privateTransferSevenSeaterPriceAdult &&
                        !(
                            state.excursions[excursionIndex]?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceAdult &&
                            state.excursions[excursionIndex]?.ticketPricing
                                ?.privateTransferFourteenSeaterPriceChild &&
                            totalPax > 6
                        )
                    ) {
                        state.selectedExcSupplements[
                            selectedExcursionIndex
                        ].is7SeaterTransfer = true;
                        state.selectedExcSupplements[
                            selectedExcursionIndex
                        ].sevenSeaterCount = 1;
                    } else {
                        state.selectedExcSupplements[
                            selectedExcursionIndex
                        ].is14SeaterTransfer = true;
                        state.selectedExcSupplements[
                            selectedExcursionIndex
                        ].fourteenSeaterCount = 1;
                    }
                }
            }
        },
        removeSelectedExcSupplement: (state, action) => {
            const filteredExcursions = state.selectedExcSupplements?.filter(
                (item) => {
                    return item?._id !== action.payload;
                }
            );
            state.selectedExcSupplements = filteredExcursions;
            const filteredIds = state.selectedExcSupplementIds?.filter((id) => {
                return id !== action.payload;
            });
            state.selectedExcSupplementIds = filteredIds;
        },
        updateIsTransferQuotationDisabled: (state, action) => {
            state.isTransferQuotationDisabled = action.payload;
        },
        updateIsHotelQuotationDisabled: (state, action) => {
            state.isHotelQuotationDisabled = action.payload;
        },
        updateSelectedVisa: (state, action) => {
            if (Object.keys(action.payload).length < 1) {
                state.selectedVisa = {};
            } else {
                let visa = { ...action.payload };
                visa.visaId = action.payload?._id;
                state.selectedVisa = visa;
            }
        },
        handleQuotationCurrencyChange: (state, action) => {
            state.quotationCurrency = action.payload;
        },
        handleMarkupChange: (state, action) => {
            state.markup = action.payload;
        },
        handleMarkupTypeChange: (state, action) => {
            state.markupType = action.payload;
        },
        setQuotationData: (state, action) => {
            state.clientName =
                action.payload?.amendment?.clientName || "Sir / Madam";
            state.noOfAdults = action.payload?.amendment?.noOfAdults;
            state.noOfChildren = action.payload?.amendment?.noOfChildren;
            state.childrenAges = action.payload?.amendment?.childrenAges
                ? action.payload?.amendment?.childrenAges
                : [];
            state.checkInDate = action.payload?.amendment?.checkInDate;
            state.checkOutDate = action.payload?.amendment?.checkOutDate;

            if (action.payload?.amendment?.transferQuotation) {
                state.transfer = action.payload?.amendment?.transferQuotation;
            }
            state.isTransferQuotationDisabled =
                action.payload?.amendment?.isTransferQuotationDisabled;
            state.isHotelQuotationDisabled =
                action.payload?.amendment?.isHotelQuotationDisabled;

            if (action.payload?.amendment?.hotelQuotation) {
                state.hotelQt = action.payload?.amendment?.hotelQuotation;
            } else {
                state.hotelDisabledRemark =
                    action.payload?.amendment?.hotelDisabledRemark || "";
            }

            if (action.payload?.amendment?.excursionQuotation) {
                state.selectedExcursions =
                    action.payload?.amendment?.excursionQuotation?.excursions;
                state.selectedExcursionsIds =
                    action.payload?.amendment?.excursionQuotation?.excursions?.map(
                        (exc) => {
                            return exc?._id;
                        }
                    );
            }

            if (action.payload?.amendment?.excSupplementQuotation) {
                state.selectedExcSupplements =
                    action.payload?.amendment?.excSupplementQuotation?.excursions;
                state.selectedExcSupplementIds =
                    action.payload?.amendment?.excSupplementQuotation?.excursions?.map(
                        (exc) => {
                            return exc?._id;
                        }
                    );
            }

            if (action.payload?.amendment?.visa) {
                state.selectedVisa = action.payload?.amendment?.visa;
                state.selectedVisa.price =
                    action.payload?.amendment?.visa?.price || "";
                state.otbPrice =
                    action.payload?.amendment?.visa?.otbPrice || "";
            }

            state.markup = action?.payload?.amendment?.markup;
            state.markupType = action?.payload?.amendment?.markupType;
            state.quotationCurrency =
                action?.payload?.amendment?.quotationCurrency;
        },
        setExcursionsTotalPrice: (state, action) => {
            state.excursionTotalPrice = action.payload;
        },
        changeSelectedHotel: (state, action) => {
            state.hotelQt.hotels[action.payload?.hotelIndex].hotelName =
                action.payload?.hotel?.name;
            state.hotelQt.hotels[action.payload?.hotelIndex].placeName =
                action.payload?.hotel?.place;
            state.hotelQt.hotels[action.payload?.hotelIndex].starCategory =
                action.payload?.hotel?.starCategory;
            state.hotelQt.hotels[action.payload?.hotelIndex].hotelId =
                action.payload?.hotel?._id;
            state.hotelQt.hotels[action.payload?.hotelIndex].roomTypeName =
                action?.payload?.hotel?.defaultRoomType?.roomTypeName || "";
        },
        setDateError: (state, action) => {
            state.dateError = action.payload;
        },
        handleCheckInChange: (state, action) => {
            state.checkInDate = action.payload;
        },
        handleCheckOutChange: (state, action) => {
            state.checkOutDate = action.payload;
        },
        handleOkToBoardChange: (state, action) => {
            state.otbPrice = action.payload;
        },
        handleHotelDisabledRemarkChange: (state, action) => {
            state.hotelDisabledRemark = action.payload;
        },
        handleExcursionTypeChange: (state, action) => {
            state.excursionTransferType = action.payload;
        },
        handleExcSupplementTransferTypeChange: (state, action) => {
            state.excSupplementTransferType = action.payload;
        },
        clearAllQtnData: (state, action) => {
            state.clientName = "Sir / Madam";
            state.noOfAdults = "";
            state.noOfChildren = "";
            state.checkInDate = "";
            state.checkOutDate = "";
            state.childrenAges = [];
            state.transfer = {
                stateId: "",
                transferFrom: "",
                transferTo: "",
                noOfSeats: "",
                isReturn: true,
                price: 0,
                is7SeaterTransfer: true,
                sevenSeaterCount: 1,
                is14SeaterTransfer: false,
                fourteenSeaterCount: 1,
            };
            state.hotelQt = {
                totalPrice: 0,
                isTourismFeeIncluded: false,
                hotels: [
                    {
                        hotelId: "",
                        hotelName: "",
                        placeName: "",
                        starCategory: "",
                        isBreakfastIncluded: false,
                        isRefundable: false,
                        roomTypeName: "",
                        isTourismFeeIncluded: true,
                        roomOccupancies: state.roomOccupancies,
                    },
                ],
            };
            state.dateError = "";
            state.selectedExcursions = [];
            state.excursionTotalPrice = 0;
            state.selectedExcursionsIds = [];
            state.excursionTransferType = "all";
            state.selectedExcSupplements = [];
            state.excSupplementsTotalPrice = 0;
            state.selectedExcSupplementIds = [];
            state.excSupplementTransferType = "all";
            state.selectedVisa = {};
            state.otbPrice = "";
            state.isTransferQuotationDisabled = false;
            state.isHotelQuotationDisabled = false;
            state.quotationCurrency = "USD";
            state.markup = "";
            state.markupType = "percentage";
            state.hotelDisabledRemark = "";
        },
        handleClientNameChange: (state, action) => {
            state.clientName = action.payload;
        },
    },
});

export const {
    setInititalData,
    handleTransferChange,
    handleRowItemChange,
    addExcusrsion,
    removeSelectedExcursion,
    addHotel,
    removeHotel,
    handleRoomOccupancyChange,
    handleHotelQtDataChange,
    handleHotelsDataChange,
    updateIsTransferQuotationDisabled,
    updateIsHotelQuotationDisabled,
    updateSelectedVisa,
    setQuotationData,
    changeExcursionType,
    handleQuotationCurrencyChange,
    handleMarkupChange,
    handleMarkupTypeChange,
    handleTourismFeeIncludedChange,
    setExcursionsTotalPrice,
    changeSelectedHotel,
    handleNoOfAdultsChange,
    handleChildrenAgeChange,
    handleNoOfChildrenChange,
    setDateError,
    updateHotelBreakfastIncludedOrNot,
    updateHotelIsRefundable,
    handleCheckInChange,
    handleCheckOutChange,
    handleOkToBoardChange,
    handleHotelDisabledRemarkChange,
    updateIsTourismFeeIncluded,
    handleExcursionTypeChange,
    changeExcursionPerPersonPrice,
    clearAllQtnData,
    handleClientNameChange,
    changeExcursionTransferData,
    addExcSupplement,
    handleExcSupplementTransferTypeChange,
    changeExcSupplementPerPersonPrice,
    changeExcSupplementTransferData,
    changeExcSupplementType,
    removeSelectedExcSupplement,
} = quotationsSlice.actions;

export default quotationsSlice.reducer;
