import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi";

import axios from "../../axios";
import { Loader } from "../../components";

export default function AdmSingleQuotationDetailsPage() {
    const [quotation, setQuotation] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { quotationNumber } = useParams();
    const { jwtToken } = useSelector((state) => state.user);

    const fetchQuotaionDetails = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(
                `/admin/quotations/single/${quotationNumber}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setQuotation(data?.quotation);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [jwtToken, quotationNumber]);

    useEffect(() => {
        fetchQuotaionDetails();
    }, [fetchQuotaionDetails]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <div className="flex items-start gap-[10px] justify-between">
                <table className="text-[15px]">
                    <tbody>
                        <tr>
                            <td className="pr-[12px] py-1">Quotation Number</td>
                            <td className="px-[12px]">:</td>
                            <td className="px-[12px]">{quotationNumber}</td>
                        </tr>
                        <tr>
                            <td className="pr-[12px] py-1">Total Amendments</td>
                            <td className="px-[12px]">:</td>
                            <td className="px-[12px]">
                                {quotation?.amendments?.length}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-[12px] py-1">Agent Name</td>
                            <td className="px-[12px]">:</td>
                            <td className="px-[12px]">
                                {quotation?.agent?.name}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-[12px] py-1">Agent Email</td>
                            <td className="px-[12px]">:</td>
                            <td className="px-[12px]">
                                {quotation?.agent?.email}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mt-5">
                {quotation?.amendments?.map((amendment, index) => {
                    return (
                        <AmendmentTable
                            key={index}
                            amendment={amendment}
                            quotation={quotation}
                            index={index}
                        />
                    );
                })}
            </div>
        </div>
    );
}

const AmendmentTable = ({ amendment, quotation, index }) => {
    return (
        <div className="border border-dashed bg-[#f6f6f6] p-5 mb-10">
            <div className="flex justify-between">
                <h3 className="font-[600] text-blue-500 text-base">
                    Amendment {quotation?.amendments?.length - index}
                    <span className="ml-5 text-green-500">
                        ({new Date(amendment?.createdAt).toLocaleDateString()})
                    </span>
                </h3>
                <div className="flex items-center gap-3">
                    <a
                        href={process.env.REACT_APP_SERVER_URL + amendment?.pdf}
                        target="blank"
                    >
                        <button className="px-3 flex items-center gap-[10px]">
                            <FiDownload /> Download Pdf
                        </button>
                    </a>
                    <a
                        href={
                            process.env.REACT_APP_SERVER_URL + amendment?.sheet
                        }
                        download
                    >
                        <button className="px-3 flex items-center gap-[10px]">
                            <FiDownload /> Download Sheet
                        </button>
                    </a>
                </div>
            </div>
            <div className="mt-3">
                <table>
                    <tbody className="text-[15px]">
                        <tr>
                            <td className="pr-[12px] py-1">No Of Adults</td>
                            <td className="px-[10px]">:</td>
                            <td className="px-[10px]">
                                {amendment?.noOfAdults || 0}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-[12px] py-1">No Of Children</td>
                            <td className="px-[10px]">:</td>
                            <td className="px-[10px]">
                                {amendment?.noOfChildren || 0}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-[12px] py-1">Package</td>
                            <td className="px-[10px]">:</td>
                            <td className="px-[10px]">
                                {amendment?.noOfNights}N /{" "}
                                {amendment?.noOfNights + 1}D
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-[12px] py-1">Check In</td>
                            <td className="px-[10px]">:</td>
                            <td className="px-[10px]">
                                {new Date(
                                    amendment?.checkInDate
                                ).toDateString()}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-[12px] py-1">Check Out</td>
                            <td className="px-[10px]">:</td>
                            <td className="px-[10px]">
                                {new Date(
                                    amendment?.checkOutDate
                                ).toDateString()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {amendment?.transferQuotation && (
                <div className="text-[15px] mt-4">
                    <h4 className="font-medium text-gray-500 mb-1">
                        Transfer Quotaion
                    </h4>
                    <table className="">
                        <tbody>
                            <tr>
                                <td className="pr-[12px] py-1">
                                    Transfer From
                                </td>
                                <td className="px-[12px]">:</td>
                                <td className="px-[12px]">
                                    {
                                        amendment?.transferQuotation
                                            ?.transferFromName
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-[12px] py-1">Transfer To</td>
                                <td className="px-[12px]">:</td>
                                <td className="px-[12px]">
                                    {
                                        amendment?.transferQuotation
                                            ?.transferToName
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {amendment?.hotelQuotation ? (
                <div className="mt-6">
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <th className="font-[500] text-[15px] border px-[10px] py-[10px]">
                                    Star Category
                                </th>
                                <th className="font-medium text-[15px] border px-[10px]">
                                    Name of Hotel
                                </th>
                                <th className="font-medium text-[15px] border px-[10px]">
                                    Location
                                </th>
                                {amendment?.hotelQuotation?.hotels[0]?.roomOccupancies?.map(
                                    (roomOccupancy, index) => {
                                        return (
                                            <th
                                                key={index}
                                                className="font-medium text-[15px] border px-[10px]"
                                            >
                                                {
                                                    roomOccupancy?.roomOccupancyShortName
                                                }
                                            </th>
                                        );
                                    }
                                )}
                            </tr>
                        </thead>
                        <tbody className="text-[15px]">
                            {amendment?.hotelQuotation?.hotels?.map(
                                (hotel, index) => {
                                    return (
                                        <tr key={index} className="">
                                            <td className="border px-[10px] py-[5px]">
                                                {hotel?.starCategory
                                                    ? hotel?.starCategory
                                                    : "N/A"}
                                            </td>
                                            <td className="border px-[10px]">
                                                {hotel?.hotelName || "N/A"}
                                                <br />
                                                {hotel?.roomOccupancyName && (
                                                    <>
                                                        <span className="">
                                                            *{" "}
                                                            {
                                                                hotel?.roomOccupancyName
                                                            }
                                                        </span>
                                                        <br />
                                                    </>
                                                )}
                                                <span className="block mt-1">
                                                    *{" "}
                                                    {hotel.isBreakfastIncluded
                                                        ? "Breakfast Included"
                                                        : "Room Only"}
                                                </span>
                                                <span className="block mt-1">
                                                    *{" "}
                                                    {hotel?.isRefundable
                                                        ? "Refundable"
                                                        : "Non Refundable"}
                                                </span>
                                            </td>
                                            <td className="border px-[10px]">
                                                {hotel?.placeName || "N/A"}
                                            </td>
                                            {hotel?.roomOccupancies?.map(
                                                (roomOccupancy, index) => {
                                                    return (
                                                        <td
                                                            key={index}
                                                            className="border px-[10px]"
                                                        >
                                                            {roomOccupancy?.perPersonTotalPrice
                                                                ? roomOccupancy?.perPersonTotalPrice?.toFixed(
                                                                      2
                                                                  ) +
                                                                  " " +
                                                                  amendment?.quotationCurrency
                                                                : "N/A"}
                                                        </td>
                                                    );
                                                }
                                            )}
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="mt-5 text-[15px]">
                    {amendment?.noOfAdults ? (
                        <div>
                            Per person Adult price:{" "}
                            {amendment?.perPersonAdultPrice?.toFixed(2)}{" "}
                            {amendment?.quotationCurrency}
                        </div>
                    ) : (
                        ""
                    )}
                    {amendment?.noOfChildren ? (
                        <div className="mt-1">
                            Per person Child price:{" "}
                            {amendment?.perPersonChildPrice?.toFixed(2)}{" "}
                            {amendment?.quotationCurrency}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            )}

            <div className="text-[15px] mt-7">
                <h5 className="text-gray-500 font-medium mb-1">Note</h5>
                <ul className="text-[15px] list-disc ml-[16px]">
                    <li>
                        {amendment?.hotelQuotation?.isTourismFeeIncluded ===
                        true
                            ? "Tourism fee included"
                            : "Tourism fee not included"}
                    </li>
                </ul>
            </div>

            {amendment?.excursionQuotation && (
                <div className="text-[15px] mt-7">
                    <h5 className="text-gray-500 font-medium mb-1">
                        Excursion Quotaion
                    </h5>
                    <ul className="text-[15px] list-disc ml-[16px]">
                        {amendment?.excursionQuotation?.excursions?.map(
                            (excursion, index) => {
                                return (
                                    <li key={index} className="mb-1">
                                        {excursion?.excursionName} -{" "}
                                        <span className="capitalize">
                                            {excursion?.excursionType ===
                                            "ticket"
                                                ? excursion?.value === "ticket"
                                                    ? "Only Ticket"
                                                    : excursion?.value ===
                                                      "shared"
                                                    ? "Tickets With SIC Transfer"
                                                    : excursion?.value ===
                                                      "private"
                                                    ? "Tickets With PVT Transfer"
                                                    : ""
                                                : excursion?.excursionType ===
                                                  "transfer"
                                                ? excursion?.value === "private"
                                                    ? "Only Transfer (Private)"
                                                    : excursion?.value ===
                                                      "shared"
                                                    ? "Only Transfer (SIC)"
                                                    : ""
                                                : ""}
                                        </span>
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </div>
            )}

            {amendment?.excSupplementQuotation && (
                <div>
                    <h5 className="text-gray-500 font-medium mb-1 mt-7">
                        Excursion Supplements
                    </h5>

                    <ul className="text-[15px] list-disc ml-[16px]">
                        {amendment?.excSupplementQuotation?.excursions?.map(
                            (excursion, index) => {
                                return (
                                    <li
                                        key={index}
                                        style={{ marginBottom: "2px" }}
                                        className="cust-border"
                                    >
                                        {excursion?.excursionName} -{" "}
                                        <span className="capitalize">
                                            {excursion?.excursionType ===
                                            "ticket"
                                                ? excursion?.value === "ticket"
                                                    ? "Only Ticket"
                                                    : excursion?.value ===
                                                      "shared"
                                                    ? "Tickets With SIC Transfer"
                                                    : excursion?.value ===
                                                      "private"
                                                    ? "Tickets With PVT Transfer"
                                                    : ""
                                                : excursion?.excursionType ===
                                                  "transfer"
                                                ? excursion?.value === "private"
                                                    ? "Only Transfer (Private)"
                                                    : excursion?.value ===
                                                      "shared"
                                                    ? "Only Transfer (SIC)"
                                                    : ""
                                                : ""}
                                        </span>
                                        <span>
                                            {" "}
                                            - (Adult -{" "}
                                            {amendment?.quotationCurrency ===
                                            "AED"
                                                ? excursion?.adultPrice
                                                : (
                                                      excursion?.adultPrice /
                                                      3.65
                                                  )?.toFixed(0)}{" "}
                                            {amendment?.quotationCurrency},
                                            Child -{" "}
                                            {amendment?.quotationCurrency ===
                                            "AED"
                                                ? excursion?.childPrice
                                                : (
                                                      excursion?.childPrice /
                                                      3.65
                                                  )?.toFixed(0)}{" "}
                                            {amendment?.quotationCurrency})
                                        </span>
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </div>
            )}

            {amendment?.visa && (
                <div className="mt-7 text-[15px]">
                    <h5 className="text-gray-500 font-medium mb-1">Visa</h5>
                    <span className="">
                        {amendment?.visa?.name} -{" "}
                        {amendment?.visa?.ppTotalPrice} AED
                    </span>
                </div>
            )}

            <div className="mt-5">
                <span className="text-[15px] text-gray-500 block mt-1">
                    Markup -{" "}
                    <span className="text-[#222] font-medium">
                        {amendment?.markup}
                        {amendment?.markupType === "flat"
                            ? " " + amendment?.quotationCurrency
                            : "%"}
                    </span>
                </span>
            </div>
        </div>
    );
};
