import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    addHotel,
    handleHotelDisabledRemarkChange,
    updateIsHotelQuotationDisabled,
    updateIsTourismFeeIncluded,
} from "../../../redux/slices/quotationsSlice";
import SingleHotelForm from "./SingleHotelForm";

import "react-datepicker/dist/react-datepicker.css";

export default function HotelQuotationForm() {
    const { hotelQt, isHotelQuotationDisabled, hotelDisabledRemark } =
        useSelector((state) => state.quotations);
    const dispatch = useDispatch();

    return (
        <div>
            <h1 className="text-base font-[600] mb-7 text-blue-500">
                Hotel Quotation
            </h1>
            {hotelQt?.hotels?.map((hotel, hotelIndex) => {
                return (
                    <SingleHotelForm
                        hotel={hotel}
                        key={hotelIndex}
                        hotelIndex={hotelIndex}
                    />
                );
            })}
            <button
                type="button"
                className="text-blue-500 font-medium text-sm underline"
                onClick={() => dispatch(addHotel())}
            >
                Add New Hotel
            </button>

            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Tourism Fee included
                </label>
                <div className="flex flex-wrap items-center gap-[30px]">
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            name="tourism-fee"
                            className="w-[18px] h-[18px]"
                            onChange={(e) =>
                                dispatch(updateIsTourismFeeIncluded(true))
                            }
                            checked={hotelQt?.isTourismFeeIncluded === true}
                        />
                        <label htmlFor="" className="mb-0">
                            Yes
                        </label>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            name="tourism-fee"
                            className="w-[18px] h-[18px]"
                            onChange={(e) =>
                                dispatch(updateIsTourismFeeIncluded(false))
                            }
                            checked={hotelQt?.isTourismFeeIncluded === false}
                        />
                        <label htmlFor="" className="mb-0">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-[10px] mt-5">
                <input
                    type="checkbox"
                    className="w-[16px] h-[16px]"
                    defaultChecked={isHotelQuotationDisabled}
                    onChange={(e) =>
                        dispatch(
                            updateIsHotelQuotationDisabled(e.target.checked)
                        )
                    }
                />
                <label htmlFor="" className="mb-0">
                    Please check this box if you don't need hotel quotation
                </label>
            </div>
            {isHotelQuotationDisabled && (
                <div className="flex items-start gap-[2em] mt-6">
                    <label htmlFor="" className="w-[100%] max-w-[180px]">
                        Remark
                    </label>
                    <textarea
                        name=""
                        id=""
                        placeholder="Enter hotel quotation disabled remark"
                        value={hotelDisabledRemark || ""}
                        onChange={(e) =>
                            dispatch(
                                handleHotelDisabledRemarkChange(e.target.value)
                            )
                        }
                        className="h-[100px]"
                    ></textarea>
                </div>
            )}
        </div>
    );
}
