import { BsGrid, BsShop } from "react-icons/bs";

const agentSidebarLinks = [
    {
        name: "Dashboard",
        to: "/",
        icon: <BsGrid />,
    },
    {
        name: "Create Quotation",
        to: "/quotations/create",
        icon: <BsShop />,
    },
    // {
    //     name: "Create Quotaion",
    //     to: "/quotaions/create",
    //     icon: <BsShop />,
    // },
];

export default agentSidebarLinks;
