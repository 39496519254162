import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../axios";

const getToken = () => {
    if (localStorage.getItem("token")) {
        return localStorage.getItem("token");
    } else {
        return "";
    }
};

const initialState = {
    isLoggedIn: false,
    user: {},
    jwtToken: getToken(),
    allUsers: [],
    totalUsers: 0,
    loading: false,
    countries: [],
    states: [],
};

const fetchUsers = createAsyncThunk(
    "/user/fetchUsers",
    async (dispatch, getState) => {
        const response = await axios.get("/admin/all");
        return response.data;
    }
);

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUser: (state, action) => {
            state.user = action.payload?.user;
            state.jwtToken = action.payload?.jwtToken;
            state.isLoggedIn = true;

            localStorage.setItem("token", state.jwtToken);
        },
        updateUser: (state, action) => {
            state.user = action.payload;
            state.isLoggedIn = true;
        },
        logoutUser: (state, action) => {
            state.isLoggedIn = false;
            state.user = {};
            state.jwtToken = "";

            localStorage.removeItem("token");
        },
        setCountiesAndStates: (state, action) => {
            state.countries = action.payload?.countries;
            state.states = action.payload?.states;
        },
    },
    extraReducers: {
        [fetchUsers.fulfilled]: (state, action) => {
            state.allUsers = action.payload.users;
            state.totalUsers = action.payload.totalUsers;
            state.loading = false;
        },
    },
});

export const {
    saveUser,
    logout,
    updateUser,
    logoutUser,
    setCountiesAndStates,
} = userSlice.actions;

export { fetchUsers };

export default userSlice.reducer;
