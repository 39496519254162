import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    handleOkToBoardChange,
    updateSelectedVisa,
} from "../../../redux/slices/quotationsSlice";

export default function VisaQuotaionForm() {
    const { visas, selectedVisa, otbPrice } = useSelector(
        (state) => state.quotations
    );
    const dispatch = useDispatch();

    return (
        <div>
            <h1 className="text-base font-[600] mb-7 text-blue-500">
                Visa Quotation
            </h1>
            <div className="flex items-start gap-[2em]">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Visas
                </label>
                <div className="flex flex-wrap items-center gap-[30px]">
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            className="w-[18px] h-[18px]"
                            name="visa"
                            value=""
                            onChange={() => dispatch(updateSelectedVisa({}))}
                            defaultChecked
                        />
                        <label htmlFor="" className="mb-0">
                            Not required
                        </label>
                    </div>
                    {visas?.map((visa, index) => {
                        return (
                            <div
                                className="flex items-center gap-[10px]"
                                key={index}
                            >
                                <input
                                    type="radio"
                                    className="w-[18px] h-[18px]"
                                    name="visa"
                                    value={visa?._id}
                                    onChange={() =>
                                        dispatch(updateSelectedVisa(visa))
                                    }
                                    defaultChecked={selectedVisa?.visaId === visa?._id}
                                />
                                <label className="mb-0">
                                    {visa?.name}{" "}
                                    <span className="text-sm text-gray-500">
                                        ({visa?.price} AED)
                                    </span>
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
            {Object.keys(selectedVisa).length > 0 && (
                <div className="flex items-start gap-[2em] mt-6">
                    <label htmlFor="" className="w-[100%] max-w-[180px]">
                        OTB Price
                    </label>
                    <input
                        type="number"
                        placeholder="Enter the price"
                        value={otbPrice || ""}
                        onChange={(e) =>
                            dispatch(handleOkToBoardChange(e.target.value))
                        }
                        onWheel={(e) => e.target.blur()}
                    />
                </div>
            )}
            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Total
                </label>
                <span className="text-sm font-medium">
                    {Object.keys(selectedVisa).length > 0
                        ? selectedVisa?.price + Number(otbPrice)
                        : "0.00"}{" "}
                    AED
                </span>
            </div>
        </div>
    );
}
