import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import axios from "../axios";
import { AgentSidebar, Navbar } from "../components";
import { setInititalData } from "../redux/slices/quotationsSlice";

export default function AgentLayout() {
    const { jwtToken } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    
    const fetchInitialData = async () => {
        try {
            const response = await axios.get("/agents/initital-data", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            dispatch(setInititalData(response.data));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchInitialData();
    }, []);
    
    return (
        <div>
            <AgentSidebar />
            <main className="ml-[15em]">
                <Navbar />
                <div className="p-[30px]">
                    <Outlet />
                </div>
            </main>
        </div>
    );
}
