import React from "react";
import { Outlet } from "react-router-dom";
import { Navbar, AdminSidebar } from "../components";

export default function AdminLayout() {
    return (
        <div>
            <AdminSidebar />
            <main className="ml-[15em]">
                <Navbar />
                <div className="p-[30px]">
                    <Outlet />
                </div>
            </main>
        </div>
    );
}
