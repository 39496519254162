import { configureStore } from "@reduxjs/toolkit";
import {
    quotationsListReducer,
    quotationsReducer,
    userReducer,
} from "./slices";

const store = configureStore({
    reducer: {
        user: userReducer,
        quotations: quotationsReducer,
        quotationsList: quotationsListReducer,
    },
    devTools: true,
});

export default store;
