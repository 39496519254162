import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import axios from "../../../axios";
import { BtnLoader, Loader } from "../../../components";

export default function AirportEditPage() {
    const [data, setData] = useState({
        name: "",
        shortName: "",
        description: "",
        countryId: "",
        stateId: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    const navigate = useNavigate();
    const { jwtToken, countries, states } = useSelector((state) => state.user);
    const { id } = useParams();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            await axios.patch(
                `/admin/airports/update/${id}`,
                {
                    ...data,
                },
                { headers: { Authorization: `Bearer ${jwtToken}` } }
            );

            navigate("/admin/airports");
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const fetchAirport = useCallback(
        async (req, res) => {
            try {
                setIsFetching(true);

                const { data } = await axios.get(
                    `/admin/airports/single/${id}`,
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                setData((prev) => {
                    return {
                        ...prev,
                        name: data?.name,
                        shortName: data?.shortName,
                        description: data?.description,
                        countryId: data?.country,
                        stateId: data?.state,
                    };
                });

                setIsFetching(false);
            } catch (err) {
                console.log(err);
            }
        },
        [jwtToken, id]
    );

    useEffect(() => {
        setData((prev) => {
            return { ...prev, countryId: countries[0]?._id };
        });
    }, [countries]);

    useEffect(() => {
        fetchAirport();
    }, [fetchAirport]);

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div className="mx-auto max-w-screen-sm">
            <h2 className="font-[600] text-base mb-5">Edit Airport</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="countryField">Country Name</label>
                    <select
                        id="countryField"
                        className="w-full"
                        name="countryId"
                        value={data.countryId}
                        onChange={handleChange}
                        required
                    >
                        {countries?.map((country, index) => (
                            <option value={country._id} key={index}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Select Emirate</label>
                    <select
                        name="stateId"
                        id=""
                        className="w-full"
                        onChange={handleChange}
                        value={data.stateId || ""}
                        required
                    >
                        <option value="" hidden>
                            Select Emirate
                        </option>
                        {states?.map((state, index) => {
                            return (
                                <option value={state?._id} key={index}>
                                    {state?.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="input-place">Airport Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter Airport Name"
                        id="input-place"
                        name="name"
                        value={data.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="place mb-5">
                    <label htmlFor="input-place">Short Name</label>
                    <input
                        type="text"
                        className=""
                        placeholder="Enter Airport's Short Name"
                        id="input-place"
                        name="shortName"
                        value={data.shortName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="description mb-5">
                    <label htmlFor="input-description">Description</label>
                    <textarea
                        type="text"
                        placeholder="Enter description"
                        id="input-description"
                        name="description"
                        value={data.description}
                        onChange={handleChange}
                    />
                </div>

                {error && (
                    <span className="text-sm block mb-2 text-red-500">
                        {error}
                    </span>
                )}
                <button className="w-[100%]" disabled={isLoading}>
                    {isLoading ? <BtnLoader /> : "Update Airport"}
                </button>
            </form>
        </div>
    );
}
