import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "../../../axios";
import { Loader } from "../../../components";
import { SingleTransferTableRow } from "../../../features/TransferMarkup";

function MarkupsTransferList() {
    const [transfers, setTransfers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const { id } = useParams();

    const datafetch = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(
                `/admin/transfers/markups/organizations/${id}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setTransfers(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        datafetch();
    }, []);

    return (
        <div className="">
            <div className="flex justify-between mb-5">
                <h1 className="text-lg font-[600]">Transfers</h1>
                <Link to="/admin/transfers/create">
                    <button className="px-4 bg-darkColor py-2 rounded-md">
                        + Create
                    </button>
                </Link>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <table className="w-full text-sm text-left text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="py-3 px-3">
                                From
                            </th>
                            <th scope="col" className="py-3 px-3">
                                To
                            </th>
                            <th scope="col" className="py-3 px-3">
                                7 seater
                            </th>
                            <th scope="col" className="py-3 px-3">
                                14 seater
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Merkup
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {transfers &&
                            transfers.map((transfer) => (
                                <SingleTransferTableRow
                                    transfer={transfer}
                                    key={transfer?._id}
                                />
                            ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default MarkupsTransferList;
