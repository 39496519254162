import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";

import axios from "../../../axios";
import { Loader } from "../../../components";

function PlaceLists() {
    const [places, setPlaces] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);

    const datafetch = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get("/admin/places/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setPlaces(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleDelete = async (id) => {
        try {
            const isConfirm = window.confirm("Are you sure to delete?");
            if (isConfirm) {
                await axios.delete(`/admin/places/delete/${id}`, {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                });
                const filteredItems = places.filter((item) => {
                    return item?._id !== id;
                });
                setPlaces(filteredItems);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        datafetch();
    }, [datafetch]);

    return (
        <div className="">
            <div className="flex justify-between mb-5">
                <h1 className="text-lg font-[600]">Places</h1>
                <Link to="/admin/places/create">
                    <button className="px-4 bg-darkColor text-[#fff] py-2 rounded-md mb-1">
                        + Create
                    </button>
                </Link>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <table className="w-full text-sm text-left text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="py-3 px-3">
                                Name
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Country
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Emirate
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Descriptions
                            </th>
                            <th className="py-3 px-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {places?.map((place, index) => (
                            <tr key={index} className="bg-white border-b ">
                                <td className="py-3 px-3">{place?.name} </td>
                                <td className="py-3 px-3">
                                    {place?.country?.name}{" "}
                                </td>
                                <td className="py-3 px-3">
                                    {place?.state?.name}{" "}
                                </td>
                                <td className="py-3 px-3">
                                    {place?.description || "N/A"}
                                </td>
                                <td className="py-3 px-3">
                                    <div className="flex items-center gap-[15px]">
                                        <Link
                                            to={`/admin/places/edit/${place?._id}`}
                                        >
                                            <button className="flex items-center justify-center h-auto p-0 text-green-500 text-xl bg-transparent">
                                                <BiEditAlt />
                                            </button>
                                        </Link>
                                        <button
                                            onClick={() =>
                                                handleDelete(place?._id)
                                            }
                                            className="flex items-center justify-center p-0 h-auto text-red-500 text-xl bg-transparent"
                                        >
                                            <MdDelete />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default PlaceLists;
