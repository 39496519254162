import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import axios from "../../../axios";
import { Loader } from "../../../components";
import AgentsTableSingleRow from "../Agents/AgentsTableSingleRow";

export default function OrganizationsAgentsListPage() {
    const [agents, setAgents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);
    const { id } = useParams();

    const fetchAgents = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(
                `/admin/organizations/${id}/agents`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setAgents(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, [jwtToken]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    return (
        <div className="">
            <div className="flex justify-between mb-5">
                <h1 className="text-lg font-[600]">Agents</h1>
                <Link to="create">
                    <button className="px-4 bg-darkColor text-[#fff] py-2 rounded-md mb-1">
                        + Create
                    </button>
                </Link>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <table className="w-full text-sm text-left text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="py-3 px-3">
                                Agent Code
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Name
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Email
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Position
                            </th>
                            <th className="py-3 px-3">Block</th>
                            <th className="py-3 px-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {agents?.map((agent, index) => (
                            <AgentsTableSingleRow
                                key={index}
                                agent={agent}
                                agents={agents}
                                setAgents={setAgents}
                            />
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}
