import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import axios from "../../../axios";
import { Loader } from "../../../components";

export default function OrganizationsListPage() {
    const [organizations, setOrganizations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.user);

    const fetchOrganizations = async () => {
        try {
            setIsLoading(true);

            const response = await axios.get("/admin/organizations/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setOrganizations(response.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async (id) => {
        try {
            const isConfirm = window.confirm("Are you sure to delete?");
            if (isConfirm) {
                await axios.delete(`/admin/organizations/delete/${id}`, {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                });

                const filteredOrganizations = organizations.filter((org) => {
                    return org?._id !== id;
                });
                setOrganizations(filteredOrganizations);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchOrganizations();
    }, []);

    return (
        <div className="">
            <div className="flex justify-between mb-5">
                <h1 className="text-lg font-[600]">Organizations</h1>
                <Link to="/admin/organizations/create">
                    <button className="px-4 bg-darkColor text-[#fff] py-2 rounded-md mb-1">
                        + Create
                    </button>
                </Link>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <table className="w-full text-sm text-left text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="py-3 px-3">
                                Org Name
                            </th>
                            <th scope="col" className="py-3 px-3">
                                View Agents
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Transfer Markup
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Excursion Markup
                            </th>
                            <th scope="col" className="py-3 px-3">
                                Visa Markup
                            </th>
                            <th className="py-3 px-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {organizations?.map((org, index) => (
                            <tr
                                key={index}
                                className="bg-white border-b hover:bg-[#f3f6f9]"
                            >
                                <td className="py-3 px-3">
                                    {org?.organizationName}
                                </td>
                                <td className="py-3 px-3">
                                    <Link
                                        to={`${org?._id}/agents`}
                                        className="text-sm text-blue-500 underline"
                                    >
                                        View
                                    </Link>
                                </td>
                                <td className="py-3 px-3">
                                    <Link
                                        to={`${org?._id}/transfers/markup`}
                                        className="text-sm text-blue-500 underline"
                                    >
                                        Edit
                                    </Link>
                                </td>
                                <td className="py-3 px-3">
                                    <Link
                                        to={`${org?._id}/excursions/markup`}
                                        className="text-sm text-blue-500 underline"
                                    >
                                        Edit
                                    </Link>
                                </td>
                                <td className="py-3 px-3">
                                    <Link
                                        to={`${org?._id}/visas/markup`}
                                        className="text-sm text-blue-500 underline"
                                    >
                                        Edit
                                    </Link>
                                </td>
                                <td className="py-3 px-3">
                                    <div className="flex items-center gap-[15px] w-min">
                                        <Link
                                            to={`/admin/organizations/edit/${org?._id}`}
                                        >
                                            <button className="flex items-center justify-center h-auto p-0 text-green-500 text-xl bg-transparent">
                                                <BiEditAlt />
                                            </button>
                                        </Link>
                                        <button
                                            onClick={() =>
                                                handleDelete(org?._id)
                                            }
                                            className="flex items-center justify-center p-0 h-auto text-red-500 text-xl bg-transparent"
                                        >
                                            <MdDelete />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}
