import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";

import axios from "../../../axios";
import { BtnLoader } from "../../../components";
import {
    handleMarkupChange,
    handleMarkupTypeChange,
    handleQuotationCurrencyChange,
} from "../../../redux/slices/quotationsSlice";

export default function QuotationSubmissionForm({ isEdit = false }) {
    const [markupError, setMarkupError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const { jwtToken } = useSelector((state) => state.user);
    const {
        hotelQt,
        transfer,
        selectedVisa,
        selectedExcursions,
        isTransferQuotationDisabled,
        isTourismFeeIncluded,
        quotationCurrency,
        markup,
        markupType,
        noOfAdults,
        noOfChildren,
        childrenAges,
        isHotelQuotationDisabled,
        dateError,
        checkInDate,
        checkOutDate,
        otbPrice,
        hotelDisabledRemark,
        clientName,
        selectedExcSupplements,
    } = useSelector((state) => state.quotations);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { quotationNumber } = useParams();

    const handleSubmitQuotation = async () => {
        try {
            if (dateError) {
                return setError(dateError);
            }

            if (!markup) {
                return setMarkupError(true);
            }

            // console.log(moment(checkInDate).format());

            if (!markupError) {
                setIsLoading(true);
                setError("");

                let response;
                if (isEdit) {
                    response = await axios.patch(
                        `/quotations/update/${quotationNumber}`,
                        {
                            transfer,
                            hotelQt,
                            selectedExcursions,
                            isTourismFeeIncluded: isTourismFeeIncluded,
                            quotationCurrency,
                            markup: markup,
                            markupType,
                            visaId: selectedVisa?.visaId,
                            isTransferQuotationDisabled,
                            noOfAdults,
                            noOfChildren,
                            childrenAges,
                            isHotelQuotationDisabled,
                            checkInDate: checkInDate
                                ? moment(checkInDate).format().substring(0, 10)
                                : "",
                            checkOutDate: checkOutDate
                                ? moment(checkOutDate).format().substring(0, 10)
                                : "",
                            otbPrice,
                            hotelDisabledRemark,
                            clientName,
                            selectedExcSupplements,
                        },
                        {
                            headers: { Authorization: `Bearer ${jwtToken}` },
                        }
                    );
                } else {
                    response = await axios.post(
                        "/quotations/create",
                        {
                            isTransferQuotationDisabled,
                            isHotelQuotationDisabled,
                            transfer,
                            hotelQt,
                            selectedExcursions,
                            isTourismFeeIncluded: isTourismFeeIncluded,
                            quotationCurrency,
                            markup: markup,
                            markupType,
                            visaId: selectedVisa?.visaId,
                            noOfAdults,
                            noOfChildren,
                            childrenAges,
                            checkInDate: checkInDate
                                ? moment(checkInDate).format().substring(0, 10)
                                : "",
                            checkOutDate: checkOutDate
                                ? moment(checkOutDate).format().substring(0, 10)
                                : "",
                            otbPrice,
                            hotelDisabledRemark,
                            clientName,
                            selectedExcSupplements,
                        },
                        {
                            headers: { Authorization: `Bearer ${jwtToken}` },
                        }
                    );
                }

                navigate(
                    `/quotations/email/${response?.data?.quotationNumber}`
                );
            }
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
            console.log(err);
        }
    };

    useEffect(() => {
        if (
            markup &&
            markupType === "percentage" &&
            (markup > 15 || markup < 4)
        ) {
            setMarkupError(true);
        } else {
            setMarkupError(false);
        }
    }, [markupType, markup]);

    return (
        <div>
            <div className="flex items-start gap-[2em] mt-[15px]">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Create Quotation in
                </label>
                <div className="flex flex-wrap items-center gap-[30px]">
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            name="quotation-currency"
                            className="w-[18px] h-[18px]"
                            onChange={() =>
                                dispatch(handleQuotationCurrencyChange("USD"))
                            }
                            checked={quotationCurrency === "USD"}
                        />
                        <label htmlFor="" className="mb-0">
                            USD
                        </label>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <input
                            type="radio"
                            name="quotation-currency"
                            className="w-[18px] h-[18px]"
                            onChange={() =>
                                dispatch(handleQuotationCurrencyChange("AED"))
                            }
                            checked={quotationCurrency === "AED"}
                        />
                        <label htmlFor="" className="mb-0">
                            AED
                        </label>
                    </div>
                </div>
            </div>

            <div className="flex items-start gap-[2em] mt-6">
                <label htmlFor="" className="w-[100%] max-w-[180px]">
                    Markup <br />
                    (Min: 4% - Max: 15%)
                </label>

                <div className="w-full ">
                    <div className="flex items-start gap-[2em] mb-[10px]">
                        <div className="flex items-center gap-[10px]">
                            <input
                                type="radio"
                                name="markup-type"
                                className="w-[18px] h-[18px]"
                                onChange={() =>
                                    dispatch(
                                        handleMarkupTypeChange("percentage")
                                    )
                                }
                                checked={markupType === "percentage"}
                            />
                            <label htmlFor="" className="mb-0">
                                Percentage
                            </label>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <input
                                type="radio"
                                name="markup-type"
                                className="w-[18px] h-[18px]"
                                onChange={() =>
                                    dispatch(handleMarkupTypeChange("flat"))
                                }
                                checked={markupType === "flat"}
                            />
                            <label htmlFor="" className="mb-0">
                                Flat
                            </label>
                        </div>
                    </div>

                    <input
                        type="number"
                        required
                        onChange={(e) => {
                            dispatch(handleMarkupChange(e.target.value));
                        }}
                        value={markup || ""}
                        placeholder="Enter Markup"
                        onWheel={(e) => e.target.blur()}
                    />
                    <span className="text-sm block mt-[7px] text-gray-500">
                        Add your markup in {quotationCurrency}
                    </span>
                    {markupError && (
                        <span className="text-sm text-red-500 block mt-[7px]">
                            The markup value should be 4% to 15%
                        </span>
                    )}
                </div>
            </div>

            {error && (
                <div className="flex items-start gap-[2em]">
                    <span className="w-[100%] max-w-[180px]"></span>
                    <span className="text-sm text-red-500 block mt-5">
                        {error}
                    </span>
                </div>
            )}
            <div className="flex justify-end mt-6">
                <button
                    className="w-[200px]"
                    onClick={handleSubmitQuotation}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <BtnLoader />
                    ) : isEdit ? (
                        "Update Quotation"
                    ) : (
                        "Create Quotation"
                    )}
                </button>
            </div>
        </div>
    );
}
